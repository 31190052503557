export function readCookie(name) {
  let result = null;
  if (
    document.cookie.split(';').filter((cookie) => cookie.trim().startsWith(`${name}=`)).length > 0
  ) {
    let regex = new RegExp('(?:(?:^|.*;\\s*)' + name + '\\s*=\\s*([^;]*).*$)|^.*$');
    result = document.cookie.replace(regex, '$1');
  }
  return result;
}

export function setCookie(name, value, isSecure, expires) {
  if (isSecure) {
    if (expires) {
      document.cookie = `${name}=${value};path=/;secure;samesite=lax;expires=${expires};`;
    } else {
      document.cookie = `${name}=${value};path=/;secure;samesite=lax;`;
    }
  } else {
    if (expires) {
      document.cookie = `${name}=${value};path=/;samesite=lax;expires=${expires};`;
    } else {
      document.cookie = `${name}=${value};path=/;samesite=lax;`;
    }
  }
}

export function deleteCookie(name) {
  document.cookie = `${name}=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
}
