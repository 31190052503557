import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

import 'styles/scss/components/messageDialog.scss';

export default function MessageDialog(props) {
  const { open, message, handleClose } = props;
  const customization = useSelector((state) => state.event.customization);
  const { t } = useTranslation();
  const [customStyle, setCustomStyle] = useState({});

  useEffect(() => {
    if (customization.dialog) {
      if (customization.dialog.style) {
        let customStyle = {};
        for (const [key, value] of Object.entries(customization.dialog.style)) {
          customStyle[key] = value;
        }
        setCustomStyle(customStyle);
      }
    } else {
      setCustomStyle({});
    }
  }, [customization]);

  return (
    <Dialog
      className="message-dialog"
      style={customStyle}
      open={open}
      onClose={handleClose}
      aria-describedby="message-dialog-description"
    >
      <DialogTitle>
        <button className="close-btn" onClick={handleClose}>
          <CloseIcon className="icon" />
        </button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="message-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <button onClick={handleClose}>{t('app.close')}</button>
      </DialogActions>
    </Dialog>
  );
}
