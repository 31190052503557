import { useSelector } from 'react-redux';
import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';
import 'styles/scss/components/hlsjsPlayerStat.scss';

const graphSize = 20;

const previousYL = (ctx) => {
  if (ctx && ctx.dataIndex === 19) {
    return ctx.chart.scales.yLeft.getPixelForValue(ctx.raw);
  }
  return undefined;
};

const previousYR = (ctx) => {
  if (ctx && ctx.dataIndex === 19) {
    return ctx.chart.scales.yRight.getPixelForValue(ctx.raw);
  }
  return undefined;
};

export default function HlsjsPlayerStat() {
  const chartRef = useRef(null);
  const chartXRef = useRef(null);

  const qualityChartRef = useRef(null);
  const qualityChartXRef = useRef(null);

  const { latencyStat } = useSelector((state) => state.hlsjsStat);
  const { playingDate } = useSelector((state) => state.hlsjsStat);
  const bufferColor = 'rgb(52, 150, 255)';
  const latencyColor = 'rgb(12, 98, 81)';
  const playbackRateColor = 'rgb(255, 150, 50)';
  const qualityColor = 'rgb(12, 98, 81)';

  useEffect(() => {
    chartRef.current = new Chart(chartXRef.current, {
      type: 'line',
      data: {
        labels: new Array(graphSize).fill(''),
        datasets: [
          {
            label: 'Buffer (ms)',
            data: new Array(graphSize).fill(0),
            fill: false,
            borderColor: bufferColor,
            backgroundColor: bufferColor,
            yAxisID: 'yLeft',
            animation: {
              y: {
                easing: 'linear',
                from: previousYL,
              },
            },
          },
          {
            label: 'Latency (ms)',
            data: new Array(graphSize).fill(0),
            lineTension: 0.3,
            borderColor: latencyColor,
            backgroundColor: latencyColor,
            yAxisID: 'yLeft',
            animation: {
              y: {
                easing: 'linear',
                from: previousYL,
              },
            },
          },
          {
            label: 'PlaybackRate',
            data: new Array(graphSize).fill(0),
            lineTension: 0.3,
            borderColor: playbackRateColor,
            backgroundColor: playbackRateColor,
            yAxisID: 'yRight',
            animation: {
              y: {
                easing: 'linear',
                from: previousYR,
              },
            },
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          yLeft: {
            type: 'linear',
            display: true,
            position: 'left',
            suggestedMin: 0,
          },
          yRight: {
            type: 'linear',
            display: true,
            position: 'right',
            suggestedMin: 0,
            suggestedMax: 2,
            grid: {
              drawOnChartArea: false,
            },
          },
        },
      },
    });

    return () => {
      chartRef.current.destroy();
    };
  }, []);

  useEffect(() => {
    qualityChartRef.current = new Chart(qualityChartXRef.current, {
      type: 'line',
      data: {
        labels: new Array(graphSize).fill(''),
        datasets: [
          {
            label: 'Quality (kbps)',
            data: new Array(graphSize).fill(0),
            fill: false,
            borderColor: qualityColor,
            backgroundColor: qualityColor,
            yAxisID: 'yLeft',
            animation: {
              y: {
                easing: 'linear',
                from: previousYL,
              },
            },
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          yLeft: {
            type: 'linear',
            display: true,
            position: 'left',
            suggestedMin: 0,
          },
        },
      },
    });

    return () => {
      qualityChartRef.current.destroy();
    };
  }, []);

  useEffect(() => {
    const chart = chartRef.current;
    if (
      chart &&
      latencyStat.forwardBufferLength &&
      latencyStat.latency &&
      latencyStat.playbackRate
    ) {
      chart.data.datasets[0].data.push(latencyStat.forwardBufferLength);
      chart.data.datasets[1].data.push(latencyStat.latency);
      chart.data.datasets[2].data.push(latencyStat.playbackRate);
      chart.data.labels.push('');
      chart.update();
      chart.data.labels.shift();
      chart.data.datasets[0].data.shift();
      chart.data.datasets[1].data.shift();
      chart.data.datasets[2].data.shift();
      chart.update();
    }
  }, [latencyStat]);

  useEffect(() => {
    const chart = qualityChartRef.current;
    if (chart && latencyStat.curBitrate) {
      chart.data.datasets[0].data.push(latencyStat.curBitrate);
      chart.data.labels.push('');
      chart.update();
      chart.data.labels.shift();
      chart.data.datasets[0].data.shift();
      chart.update();
    }
  }, [latencyStat]);

  return (
    <div className="chart-container">
      <div className="rectangle">
        <h4>Metrics</h4>
        <p>
          Buffer Size: <strong>{latencyStat.forwardBufferLength}ms</strong>
        </p>
        <p>
          Latency Estimate: <strong>{latencyStat.latency}ms</strong>
        </p>
        <p>
          Stream Time: <strong>{playingDate}</strong>
        </p>
        <p>
          Local Time: <strong>{new Date().toISOString()}</strong>
        </p>
        <p>
          Playback Rate: <strong>{latencyStat.playbackRate}</strong>
        </p>
      </div>
      <div className="chart-wrapper">
        <canvas ref={chartXRef} />
      </div>
      <div className="chart-wrapper">
        <canvas ref={qualityChartXRef} />
      </div>
    </div>
  );
}
