import { Player, requiresServiceWorker } from '@theolive/player';
import { useCallback, useEffect, useState } from 'react';
import 'styles/scss/components/theoLivePlayer.scss';

export default function TheoLivePlayer(props) {
  const { theoLiveId } = props;
  const [player, setPlayer] = useState(null);
  const onMount = useCallback((node) => {
    if (node) {
      if (requiresServiceWorker()) {
        navigator.serviceWorker
          .register('/THEOLive.3.16.sw.js')
          .then(function (registration) {
            console.log(`Registration successful, scope is:`, registration.scope);
          })
          .catch(function (error) {
            console.log(`Service worker registration failed, error:`, error);
          });
      }
      setPlayer(new Player(node));
    }
  }, []);

  useEffect(() => {
    const loadChannel = async (channelId) => {
      if (player) {
        // Example: adding event listeners. For a full list of possible events, please visit https://developers.theo.live/docs/player-api-details
        /*
        player.addEventListener('channelloaded', (e) =>
          console.log(`${e.type} event captured: ${JSON.stringify(e)}`)
        );
        player.addEventListener('pause', (e) =>
          console.log(`${e.type} event captured: ${JSON.stringify(e)}`)
        );
        */

        // Load your channel
        await player.loadChannel(channelId);
      }
    };

    if (player && theoLiveId) {
      loadChannel(theoLiveId);
    }
    // TODO handle theoLiveId change
  }, [player, theoLiveId]);

  return (
    <div className={`theoliveplayer-container`}>
      <div ref={onMount} />
    </div>
  );
}
