import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as API from 'api/baseAPI';
import { setGlobalLoading } from 'redux/appSlice';
import EventV1 from './v1/event';
import EventV2 from './v2/event';

export default function Event(props) {
  const dispatch = useDispatch();
  const params = useParams();
  const [version, setVersion] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (!params.eventId) {
      return;
    }
    dispatch(setGlobalLoading(true));

    API.getEvent(params.eventId)
      .then((response) => {
        if (response && response.data) {
          if (typeof response.data !== 'object') {
            throw new Error('Invalid Event');
          }
          if (response.data.version) {
            setVersion(response.data.version);
          } else {
            setVersion('v1');
          }
        } else {
          throw new Error('Invalid Event');
        }
      })
      .catch(() => {
        setNotFound(true);
        dispatch(setGlobalLoading(false));
      });
  }, [dispatch, params.eventId]);

  return (
    <Fragment>
      {notFound ? (
        <main>
          <div style={{ padding: '10px' }}>
            <p>Page Not Found!</p>
          </div>
        </main>
      ) : (
        <Fragment>
          {version === 'v1' && <EventV1 {...props} />}
          {version === 'v2' && <EventV2 {...props} />}
        </Fragment>
      )}
    </Fragment>
  );
}
