const translation = {
  general: {
    minute_one: '{{ count }}分钟',
    minute_other: '{{ count }}分钟',
    hour_one: '{{ count }}小时',
    hour_other: '{{ count }}小时',
  },
  app: {
    exit: '离开',
    close: '关闭',
    footer: 'Powered by One Click Go Live Limited',
    'login-btn': '登入',
    'logout-btn': '登出',
    confirm: '确认',
  },
  login: {
    title: '用户登入',
    'email-label': '输入电邮',
    'not-registered-remark': '如还未有注册用户，请输入电邮地址并点击以下「确认」按钮',
    'otp-guide-title': '验证码已发送',
    'otp-label': '输入您的一次性验证码',
    'otp-remark-1': '我们已将您的一次性验证码发送到以下电邮地址',
    'otp-remark-2': '请于 15 分钟内完成登入步骤',
    'next-step': '下一步',
    'email-error': '电邮不正确，请重新登入',
    'unknown-error': '错误，请重新登入',
    'network-error': '检测到网络连接问题。如果问题持续,请尝试使用其他网络。',
    'otp-error': '验证码不正确，请重新输入',
    'otp-fatal-error': '验证码超时或验证码错误超过3次，请重新登入',
  },
  home: {
    title: '首页',
  },
  event: {
    info: '详情',
    'q-and-a': '问答',
    question: {
      placeholder: '设有问答环节，如您有任何提问，请在这里输入。',
      submit: '提交',
      'succeed-message': '感谢您的提问',
      'failed-message': '提交失败，请稍后再试',
      'exceeds-char-limit-message': '最多可以输入100个中文字（200字符）',
    },
    chatroom: '聊天室',
    polling: '投票',
    schedule: '时间表',
    si: {
      english: '英语',
      cantonese: '粤语',
      putonghua: '普通话',
    },
    playback: {
      'error-title': '播放失败',
      'error-message': '也许您的网络不太稳定，或者您并没有权限观看此视频',
    },
    'just-started': '刚刚开始',
    'started-ago': '{{age}}前开始',
    'concurrent-error-message': '您已经在其他设备登入',
    'connection-error-message': '网络不稳定',
    'require-login-message': '如果您有权限观看此视频，请刷新',
    'session-kicked-error-message': '您已經在其他設備登入，点击以下按钮在这设备观看',
    'view-here': '在此观看',
    unauthorized: '未授权观看',
  },
  passcode: {
    label: '请输入密码',
    enter: '确定',
    remark: '如未能取得密码，请联络您的活动主办单位查询',
    error: '密码不正确',
    'select-placeholder': '请选择',
  },
};

export default translation;
