import React, { useEffect, useRef, useState } from 'react';
import 'styles/scss/components/jwPlayer.scss';
import logger from 'utils/loggerUtils';
import { monitorJwEvents } from './playerEvents';

export default function JwPlayer(props) {
  const playerRef = useRef(null);
  const {
    options,
    playerStateCheckHandler,
    playerErrorHandler,
    playerStalledHandler,
    userActiveHandler,
    userInactiveHandler,
    enableCasting,
    embedHeaderComponentHtml,
    audioChangeHandler,
  } = props;
  const sourceRef = useRef(options.src);
  const positionBeforeSeekRef = useRef(0);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const removePlayer = () => {
    if (playerRef.current) {
      playerRef.current.remove();
      playerRef.current = null;
    }
  };

  // for handle source change
  useEffect(() => {
    if (playerRef.current && options.src && options.src !== sourceRef.current) {
      logger.log('change src');
      positionBeforeSeekRef.current = playerRef.current.getPosition();
      playerRef.current.load([
        {
          sources: [
            {
              file: options.src,
              type: 'hls',
            },
          ],
        },
      ]);
      playerRef.current.play();
    }
    sourceRef.current = options.src;
  }, [options.src]);

  useEffect(() => {
    const keyDownHandler = (e) => {
      const key = e.code;
      if (key === 'KeyH') {
        if (playerRef.current) {
          playerRef.current.setControls(false);
        }
      }
      if (key === 'KeyS') {
        if (playerRef.current) {
          playerRef.current.setControls(false);
          playerRef.current.setControls(true);
        }
      }
    };

    if (options.manualTriggerControl) {
      window.addEventListener('keydown', keyDownHandler);
    }

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [options.manualTriggerControl]);

  useEffect(() => {
    logger.log('jw mount');
    let monitor = null;
    let previousProgress = 0;
    const errorHandler = () => {
      logger.info('player error');
      playerErrorHandler();
      removePlayer();
    };
    const controlBarMonitor = new MutationObserver((mutationList) => {
      for (let i = 0; i < mutationList.length; i++) {
        const el = mutationList[i].target;
        const controlBarVisible =
          !el.classList.contains('jw-flag-user-inactive') ||
          !el.classList.contains('jw-state-playing');
        if (controlBarVisible) {
          if (userActiveHandler) {
            userActiveHandler();
          }
        } else {
          if (userInactiveHandler) {
            userInactiveHandler();
          }
        }
      }
    });

    if (!playerRef.current) {
      const setupOption = {
        base: `/static/js/jwplayer8-16-3/`,
        playlist: [
          {
            sources: [
              {
                file: sourceRef.current,
                type: 'hls',
              },
            ],
          },
        ],
        autostart: options.autoplay || false,
        repeat: options.isVod && options.fakeLiveLayout,
        liveTimeout: 0,
        responsive: true,
        width: '100%',
        aspectratio: '16:9',
        controls: !options.manualTriggerControl,
      };
      if (enableCasting) {
        setupOption['cast'] = {};
      }
      const player = (playerRef.current = window.jwplayer('jw-player-div').setup(setupOption));

      monitorJwEvents(
        player,
        player.version && player.version.substring(0, player.version.indexOf('+')),
        Date.now()
      );

      if (options.manualTriggerControl) {
        if (playerRef.current) {
          playerRef.current.setControls(true);
        }
      }

      player.on('play', (data) => {
        if (positionBeforeSeekRef.current !== 0) {
          player.seek(positionBeforeSeekRef.current);
          positionBeforeSeekRef.current = 0;
        }
        if (controlBarMonitor) {
          controlBarMonitor.disconnect();
        }
        const jwPlayerContainer = document.getElementById('jw-player-div');
        controlBarMonitor.observe(jwPlayerContainer, {
          attributes: true,
          attributeFilter: ['class'],
          childList: true,
          characterData: false,
        });
      });

      player.on('error', (data) => {
        errorHandler();
      });

      player.on('complete', (data) => {
        logger.log('complete');
        if (options.isVod) {
        } else {
          errorHandler();
        }
      });

      player.on('fullscreen', (data) => {
        setIsFullscreen(data.fullscreen);
      });

      if (monitor !== null) {
        clearInterval(monitor);
      }
      monitor = setInterval(() => {
        let currentProgress = 0;
        if (playerRef.current) {
          currentProgress = playerRef.current.getPosition();
          if (previousProgress !== currentProgress) {
            if (currentProgress !== 0 && previousProgress === 0) {
              // determine player started by time updated
              // this.$emit('instanceStarted')
            }
            playerStateCheckHandler({
              state: 'instancePlaying',
              previous: previousProgress,
              current: currentProgress,
            });
          } else {
            switch (player.getState()) {
              case 'idle':
              case 'paused':
              case 'complete':
                playerStateCheckHandler({
                  state: 'instancePaused',
                  previous: previousProgress,
                  current: currentProgress,
                });
                break;
              default:
                playerStalledHandler({ previous: previousProgress, current: currentProgress });
                break;
            }
          }
          previousProgress = currentProgress;
        } else {
          playerStalledHandler({ previous: previousProgress, current: currentProgress });
        }
      }, options.checkingInterval);
    } else {
      // update player
    }
    return () => {
      logger.log('jw unmount');
      if (monitor !== null) {
        clearInterval(monitor);
      }
      if (controlBarMonitor) {
        controlBarMonitor.disconnect();
      }
      removePlayer();
    };
  }, [
    options.isVod,
    options.autoplay,
    options.fakeLiveLayout,
    options.checkingInterval,
    options.manualTriggerControl,
    playerErrorHandler,
    playerStalledHandler,
    playerStateCheckHandler,
    userActiveHandler,
    userInactiveHandler,
    enableCasting,
  ]);

  useEffect(() => {
    if (isFullscreen) {
      if (embedHeaderComponentHtml) {
        const jwContainerEl = document.getElementById('jw-player-div');
        if (jwContainerEl) {
          const jwWrapperEl = jwContainerEl.getElementsByClassName('jw-wrapper')[0];
          if (jwWrapperEl) {
            jwWrapperEl.insertAdjacentHTML('afterbegin', embedHeaderComponentHtml);
            document.getElementById('embed-audio-selector').addEventListener('change', (e) => {
              audioChangeHandler(e.target.value);
            });
          }
        }
      }
    }

    return () => {
      const elem = document.getElementById('embed-audio-selector-container');
      if (elem) {
        elem.remove();
      }
    };
  }, [audioChangeHandler, isFullscreen, embedHeaderComponentHtml]);

  return (
    <div className={`jw-container${options.isEmbed ? ' embed' : ''}`}>
      <div id="jw-player-div" className="jw-player"></div>
    </div>
  );
}
