import React, { useEffect, useRef, useState } from 'react';
import 'styles/scss/components/videojsPlayer.scss';
import logger from 'utils/loggerUtils';
import { monitorVjsEvents } from './playerEvents';

export default function VideojSPlayer(props) {
  const playerRef = useRef(null);
  const _el = useRef(null);
  const {
    options,
    playerStateCheckHandler,
    playerErrorHandler,
    playerStalledHandler,
    userActiveHandler,
    userInactiveHandler,
    enableSubtitle,
    embedHeaderComponentHtml,
    audioChangeHandler,
  } = props;
  const sourceRef = useRef(options.src);
  const firstSeekRef = useRef(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const removePlayer = () => {
    if (playerRef.current) {
      logger.log('to dispose');
      playerRef.current.pause();
      playerRef.current.dispose();
      playerRef.current = null;
    }
  };

  // for handle source change
  useEffect(() => {
    if (playerRef.current && options.src && options.src !== sourceRef.current) {
      logger.log('change src');
      if (options.srcType === 'hls') {
        playerRef.current.src({
          src: options.src,
          type: 'application/x-mpegURL',
        });
      } else {
        playerRef.current.src({
          src: options.src,
          type: 'application/dash+xml',
        });
      }
      if (options.autoplay) {
        playerRef.current
          .play()
          .then(() => {})
          .catch((e) => {
            playerRef.current.muted(true);
            playerRef.current.play();
          });
      }
    }
    sourceRef.current = options.src;
  }, [options.src, options.srcType, options.autoplay]);

  useEffect(() => {
    const keyDownHandler = (e) => {
      const key = e.code;
      if (key === 'KeyH') {
        if (playerRef.current) {
          playerRef.current.controls(false);
        }
      }
      if (key === 'KeyS') {
        if (playerRef.current) {
          playerRef.current.controls(true);
        }
      }
    };

    if (options.manualTriggerControl) {
      window.addEventListener('keydown', keyDownHandler);
    }

    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [options.manualTriggerControl]);

  useEffect(() => {
    logger.log('vjs mount');
    let ignore = false;
    let monitor = null;
    let previousProgress = 0;
    const errorHandler = () => {
      playerErrorHandler();
      logger.info('error handle');
      removePlayer();
    };
    const controlBarMonitor = new MutationObserver((mutationList) => {
      for (let i = 0; i < mutationList.length; i++) {
        const el = mutationList[i].target;
        const controlBarVisible =
          (el.classList.contains('vjs-user-active') || el.classList.contains('vjs-paused')) &&
          el.classList.contains('vjs-has-started');
        if (controlBarVisible) {
          if (userActiveHandler) {
            userActiveHandler();
          }
        } else {
          if (userInactiveHandler) {
            userInactiveHandler();
          }
        }
      }
    });
    import(/* webpackChunkName: "videojs" */ 'video.js')
      .then((module) => module.default)
      .then(import(/* webpackChunkName: "videojs" */ 'videojs-hls-quality-selector'))
      .then(import(/* webpackChunkName: "videojs" */ 'videojs-contrib-eme'))
      .then((videojs) => {
        // make sure Video.js player is only initialized once
        if (!ignore && !playerRef.current) {
          logger.log('creating player');
          let config = {
            errorDisplay: false,
            controls: true,
            fluid: true,
            autoplay: options.autoplay ? 'any' : false,
            liveui: true, // for support dvr and allow seek to live
          };
          config['loop'] = options.fakeLiveLayout ? true : false;
          let source = null;
          if (options.srcType === 'dash') {
            source = {
              src: sourceRef.current,
              type: 'application/dash+xml',
            };
          } else {
            source = {
              src: sourceRef.current,
              type: 'application/x-mpegURL',
            };
          }
          document.getElementById('vjs-container').innerHTML =
            '<div data-vjs-player><video id="videojs-player-video-el" class="video-js vjs-big-play-centered" style="width: 100%; height: 100%" disableRemotePlayback webkit-playsinline playsInline/></div>';
          const videoEl = document.getElementById('videojs-player-video-el');
          const player = (playerRef.current = videojs(videoEl, config, () => {}));
          player.src(source);
          player.hlsQualitySelector({
            displayCurrentQuality: true,
          });

          monitorVjsEvents(player, videojs.VERSION, Date.now());

          player.on('canplaythrough', () => {
            if (!firstSeekRef.current) {
              player.liveTracker.seekToLiveEdge();
              firstSeekRef.current = true;
            }
          });

          player.on('ready', () => {
            logger.log('player is ready');

            player.tech(true).on('retryplaylist', () => {
              logger.log('retryplaylist');
              let retryPlaylistCount = 0;
              player.tech(true).on('usage', (data) => {
                if (data.name === 'hls-rendition-blacklisted') {
                  retryPlaylistCount++;
                }
              });
              setTimeout(() => {
                if (retryPlaylistCount >= 20) {
                  errorHandler();
                }
              }, 2000);
            });
          });

          if (options.manualTriggerControl) {
            const hideButton = player.controlBar.addChild('button');
            const hideButtonDom = hideButton.el();
            hideButtonDom.innerHTML = 'Hide';

            hideButtonDom.onclick = () => {
              if (playerRef.current) {
                playerRef.current.controls(false);
              }
            };

            if (playerRef.current) {
              playerRef.current.controls(true);
            }
          }

          player.on('waiting', () => {
            logger.log('player is waiting');
          });

          player.on('dispose', () => {
            logger.log('player will dispose');
          });

          player.on('loadedmetadata', () => {
            logger.log('loadmeta');
          });

          player.on('ended', () => {
            logger.log('ended');
            if (options.isVod) {
            } else {
              errorHandler();
            }
          });

          player.on('error', (data) => {
            logger.log('error');
            const error = player.error();
            if (error.code === 5) {
              player.error(null);
            } else {
              errorHandler();
            }
          });

          const videojsPlayerContainer = document.querySelector('.video-js');
          controlBarMonitor.observe(videojsPlayerContainer, {
            attributes: true,
            attributeFilter: ['class'],
            childList: false,
            characterData: false,
          });

          player.on('fullscreenchange', () => {
            setIsFullscreen(player.isFullscreen());
          });

          if (monitor !== null) {
            clearInterval(monitor);
          }

          monitor = setInterval(() => {
            let currentProgress = 0;
            if (playerRef.current) {
              currentProgress = playerRef.current.currentTime();
              if (previousProgress !== currentProgress) {
                if (currentProgress !== 0 && previousProgress === 0) {
                  // this.$emit('instanceStarted')
                }
                playerStateCheckHandler({
                  state: 'instancePlaying',
                  previous: previousProgress,
                  current: currentProgress,
                });
              } else {
                if (!player.paused() /* && !this.ended */) {
                  playerStalledHandler({ previous: previousProgress, current: currentProgress });
                } else {
                  playerStateCheckHandler({
                    state: 'instancePaused',
                    previous: previousProgress,
                    current: currentProgress,
                  });
                }
              }
              previousProgress = currentProgress;
            } else {
              playerStalledHandler({ previous: previousProgress, current: currentProgress });
            }
          }, options.checkingInterval);
        } else {
          // you can update player here [update player through props]
          // const player = playerRef.current;
          // player.autoplay(options.autoplay);
          // player.src(options.src);
        }
      });
    return () => {
      ignore = true;
      logger.log('vjs unmount');
      if (monitor !== null) {
        clearInterval(monitor);
      }
      if (controlBarMonitor) {
        controlBarMonitor.disconnect();
      }
      removePlayer();
    };
  }, [
    options.srcType,
    options.isVod,
    options.isDrm,
    options.autoplay,
    options.fakeLiveLayout,
    options.checkingInterval,
    options.manualTriggerControl,
    playerErrorHandler,
    playerStalledHandler,
    playerStateCheckHandler,
    userActiveHandler,
    userInactiveHandler,
  ]);

  useEffect(() => {
    if (isFullscreen) {
      if (embedHeaderComponentHtml) {
        if (_el.current.children && _el.current.children.length > 0)
          _el.current.children[0].insertAdjacentHTML('beforeend', embedHeaderComponentHtml);
        document.getElementById('embed-audio-selector').addEventListener('change', (e) => {
          audioChangeHandler(e.target.value);
        });
      }
    }

    return () => {
      const elem = document.getElementById('embed-audio-selector-container');
      if (elem) {
        elem.remove();
      }
    };
  }, [audioChangeHandler, isFullscreen, embedHeaderComponentHtml]);

  return (
    <div
      className={`vjs-container${options.isEmbed ? ' embed' : ''}${
        enableSubtitle ? ' subtitled' : ''
      }`}
      id="vjs-container"
      ref={_el}
    ></div>
  );
}
