import { UAParser } from 'ua-parser-js';

const parser = new UAParser();

export function isSafari() {
  const browserName = parser.getBrowser().name;
  return (
    browserName.toLowerCase().includes('safari') || browserName.toLowerCase().includes('webkit')
  );
}

export function isIOS() {
  const osName = parser.getOS().name;
  return osName.toLowerCase() === 'ios';
}

export function isNonWin1011IE() {
  const browserName = parser.getBrowser().name;
  const osVersion = parser.getOS().version;
  return browserName.includes('IE') && osVersion !== '10' && osVersion !== '11';
}

export function isChromiumBasedEdge() {
  const userAgent = parser.getUA();
  return userAgent.toLocaleLowerCase().indexOf('edg/') > -1;
}

export function isSamsungBrowser() {
  const browserName = parser.getBrowser().name;
  return browserName.toLowerCase().includes('samsung');
}

export function isChrome105() {
  const browserName = parser.getBrowser().name;
  const browserVersion = parser.getBrowser().version;

  const regex = /^105\./;
  return browserName.toLowerCase().includes('chrome') && regex.test(browserVersion);
}

export function getUserAgent() {
  return parser.getUA();
}
