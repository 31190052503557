import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as API from 'api/baseAPI';
import { v4 as uuidv4 } from 'uuid';
import logger from 'utils/loggerUtils';

const appendSameEventLimitCount = 5;

const playbackLatencyInitialState = {
  total: 0,
  count: 0,
  min: Number.MAX_VALUE,
  max: 0,
};

const playbackLogsInitialState = {
  lastViewSecondInTotal: 0,
  lastStalledSecondInTotal: 0,
  coreInfo: {
    eventId: null,
    userAgent: null,
    isVod: false,
    isLive: false,
  },
  userInfo: {
    userSessionIdentifier: null,
    userIdentifier: null,
  },
  // sr: source resolution, store viewSecond in map. e.g. `sr: { 480: 20, 720: 10, 1080: 30 }`
  // l: latency
  metadata: { sr: {}, l: null },
  lastViewResolution: null,
  eventLogs: [],
  viewId: uuidv4(), // unique view ID for event logs on different browser tab
  lastEventLog: {
    log: null,
    appendCount: 0, // used to prevent add the same log too much that caused by un expected behaviour. limit: `appendSameEventLimitCount`
  },
  sendPlaybackEventsEnabled: false,
};

export const sendPlaybackLogs = createAsyncThunk(
  'analytics-service/sendPlaybackLogs',
  async ({ interval, lastPlaybackType }, thunkAPI) => {
    let state = thunkAPI.getState().playbackLogs;
    let viewSecond = state.lastViewSecondInTotal;
    let stalledSecond = state.lastStalledSecondInTotal;
    let coreInfo = state.coreInfo;
    let userInfo = state.userInfo;
    let firedAt = new Date().toISOString();
    let browserSessionIdentifier = thunkAPI.getState().app.browserSessionIdentifier;
    let playbackType = coreInfo.isVod ? 'vod' : coreInfo.isLive ? 'live' : lastPlaybackType;
    let intervalSecond = Math.max(interval, Math.max(stalledSecond, viewSecond));

    if (coreInfo.eventId == null) {
      return thunkAPI.rejectWithValue({ error: 'invalid event id', eventLogs: state.eventLogs });
    }
    if (browserSessionIdentifier == null) {
      return thunkAPI.rejectWithValue({
        error: 'invalid browser session identifier',
        eventLogs: state.eventLogs,
      });
    }
    if (viewSecond <= 0 && stalledSecond <= 0 && Object.keys(state.eventLogs).length === 0) {
      return thunkAPI.fulfillWithValue({ eventLogs: state.eventLogs });
    }
    if (intervalSecond <= 0) {
      return thunkAPI.rejectWithValue({
        error: 'invalid interval second',
        eventLogs: state.eventLogs,
      });
    }
    if (playbackType == null) {
      return thunkAPI.rejectWithValue({
        error: 'invalid playback type',
        eventLogs: state.eventLogs,
      });
    }

    try {
      const resp = await API.sendPlaybackLogs([
        {
          eventID: coreInfo.eventId,
          userAgent: coreInfo.userAgent,
          playbackType: playbackType,
          userSessionIdentifier: userInfo.userSessionIdentifier,
          userIdentifier: userInfo.userIdentifier,
          browserSessionIdentifier: browserSessionIdentifier,
          metadata: state.metadata,
          firedAt: firedAt,
          viewSecond: viewSecond,
          stalledSecond: stalledSecond,
          intervalSecond: intervalSecond,
          playbackEvents: state.eventLogs,
        },
      ]);
      // sent api success case
      // following axios `validateStatus` default
      if (resp.status >= 200 && resp.status < 300) {
        logger.log(
          '_playbackLogs: [eventId: %s] logs sent at [%s][viewSecond: %s][intervalSecond: %s]',
          coreInfo.eventId,
          firedAt,
          viewSecond,
          intervalSecond
        );
        return thunkAPI.fulfillWithValue({ eventLogs: state.eventLogs });
      }
      // sent api fail case
      return thunkAPI.rejectWithValue({
        error: `api fail with status: ${resp.status}`,
        eventLogs: state.eventLogs,
      });
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message, eventLogs: state.eventLogs });
    }
  }
);

export const playbackLogsSlice = createSlice({
  name: 'playbackLogs',
  initialState: playbackLogsInitialState,
  reducers: {
    enableSendPlaybackEventsByConfig: (state, action) => {
      let percentage = action.payload;
      // logger.log('_playbackLogs percentage', percentage);
      if (percentage) {
        let randomNumber = Math.floor(Math.random() * 100); // 0-99
        state.sendPlaybackEventsEnabled = randomNumber < percentage;
        logger.log(
          '_playbackLogs: enable sendPlaybackEvents: [%s]',
          state.sendPlaybackEventsEnabled
        );
      }
    },
    updatePlaybackLogsCoreInfo: (state, action) => {
      state.coreInfo = action.payload;
      logger.log('_playbackLogs: updated core info: [eventId: %s]', state.coreInfo.eventId);
    },
    updatePlaybackLogsUserInfo: (state, action) => {
      state.userInfo = action.payload;
      logger.log('_playbackLogs: [eventId: %s] updated user info', state.coreInfo.eventId);
    },
    accumulatePlaybackLogsViewSecond: (state, action) => {
      state.lastViewSecondInTotal += action.payload;
      // logger.log('_playbackLogs: [eventId: %s] accumulate view second: %d ', state.coreInfo.eventId, state.lastViewSecondInTotal)

      // update resolution viewSecond
      if (state.lastViewResolution) {
        if (!state.metadata.sr[state.lastViewResolution]) {
          state.metadata.sr[state.lastViewResolution] = 0;
        }
        state.metadata.sr[state.lastViewResolution] += action.payload;
      }
    },
    accumulatePlaybackStalledSecond: (state, action) => {
      state.lastStalledSecondInTotal += action.payload;
      // logger.log('_playbackLogs: [eventId: %s] accumulate stalled second: %d ', state.coreInfo.eventId, state.lastStalledSecondInTotal)
    },
    appendEventLog: (state, action) => {
      if (!state.sendPlaybackEventsEnabled) {
        logger.log('[skipped] adding event log: ', action.payload.en, action.payload);
        return;
      }
      logger.log('adding event log: ', action.payload.en, action.payload);
      // check if the same event appended/ fired too much. ignore ca(createAt) when check equal
      // skip the checking if it's heartbeat (hb)
      if (action.payload.en !== 'hb') {
        if (
          JSON.stringify({ ...action.payload, ca: null }) ===
          JSON.stringify({ ...state.lastEventLog.log, ca: null })
        ) {
          state.lastEventLog.appendCount += 1;
          if (state.lastEventLog.appendCount > appendSameEventLimitCount) {
            logger.log(
              '_playbackLogs: append the same event conut > %d. ignore this: %s. ',
              appendSameEventLimitCount,
              state.lastEventLog.log.en
            );
            state.metadata.uelc = {
              ...state.lastEventLog.log,
            };
            return;
          }
        } else {
          state.metadata.uelc = null;
          state.lastEventLog.appendCount = 0;
          state.lastEventLog.log = { ...action.payload };
        }
      }

      // append the log if fine
      state.eventLogs = [...state.eventLogs, { ...action.payload, vvid: state.viewId }];
    },
    updateMetadata: (state, action) => {
      state.metadata = { ...state.metadata, ...action.payload };
    },
    updateLastViewResolution: (state, action) => {
      state.lastViewResolution = action.payload;
    },
    addLatencyLog: (state, action) => {
      if (state.coreInfo.isLive !== true) {
        return;
      }
      const latency = action.payload.latency;
      const datetime = action.payload.time;
      // logger.log('[addLatencyLog]', latency);
      const count = (state.metadata.l?.count ?? playbackLatencyInitialState.count) + 1;
      const total = (state.metadata.l?.total ?? playbackLatencyInitialState.total) + latency;
      const min = Math.min(state.metadata.l?.min ?? playbackLatencyInitialState.min, latency);
      const max = Math.max(state.metadata.l?.max ?? playbackLatencyInitialState.max, latency);
      const avg = total / count;
      const logs = [...(state.metadata.l?.logs ?? []), { dt: datetime, v: latency }];
      state.metadata.l = { total, count, avg, min, max, logs };
    },
  },
  extraReducers: {
    [sendPlaybackLogs.fulfilled]: (state, action) => {
      state.lastViewSecondInTotal = 0;
      state.lastStalledSecondInTotal = 0;
      state.eventLogs = [
        ...state.eventLogs.filter(
          (log) => !action.payload.eventLogs.some((obj) => log.ca === obj.ca)
        ),
      ];
      state.metadata.sr = {};
      state.metadata.pe = null;
      state.metadata.l = null;
    },
    [sendPlaybackLogs.rejected]: (state, action) => {
      // ignore the last view interval
      state.lastViewSecondInTotal = 0;
      state.lastStalledSecondInTotal = 0;
      state.metadata.sr = {};
      state.metadata.pe = null;
      state.metadata.l = null;
      logger.error(
        '_playbackLogs: [eventId: %s] send log fail; reason: %s',
        state.coreInfo.eventId,
        action.payload.error
      );
    },
  },
});

export const {
  enableSendPlaybackEventsByConfig,
  updatePlaybackLogsCoreInfo,
  updatePlaybackLogsUserInfo,
  accumulatePlaybackLogsViewSecond,
  accumulatePlaybackStalledSecond,
  appendEventLog,
  updateMetadata,
  updateLastViewResolution,
  addLatencyLog,
} = playbackLogsSlice.actions;

export default playbackLogsSlice.reducer;
