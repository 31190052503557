import { useContext, useEffect } from 'react';
import { DialogContext } from 'components/dialogContext';

export default function Home() {
  const { openDialog, closeDialog } = useContext(DialogContext);

  useEffect(() => {
    openDialog('Click to redirect to our website.', () => {
      closeDialog();
      window.location.href = 'https://www.oneclickgolive.com/';
    });
  }, [closeDialog, openDialog]);

  return (
    <main>
      <div></div>
    </main>
  );
}
