import axios from 'axios';
import { Parser } from 'm3u8-parser';

function generateFullUrl(uri, referenceUrl) {
  let _uri = uri;
  if (_uri.indexOf('http') !== 0) {
    let playbackUrlParts = referenceUrl.split('/');

    let backwardCount = (_uri.match(/\.\.\//g) || []).length; // handle '../'
    let childUri = _uri.replace(/\.\.\//g, '');
    let removeCount = backwardCount + 1; //to remove .m3u8
    playbackUrlParts = playbackUrlParts.slice(0, -removeCount);

    _uri = `${playbackUrlParts.join('/')}/${childUri}`;
    if (uri[0] === '/') {
      _uri = `${referenceUrl.split('/').slice(0, 3).join('/')}${childUri}`;
    }
  }
  return _uri;
}

export function checkPlaybackAvailable(playbackUrl) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: playbackUrl,
    })
      .then((response) => {
        var parser = new Parser();

        parser.push(response.data);
        parser.end();

        var parsedManifest = parser.manifest;

        let audioChildPlaylistGroup = {};
        let requireCheckAudio = false;
        let childPlaylistGroup = {};

        // old logic
        // put audio uri with same name together
        /* if (parsedManifest.mediaGroups && parsedManifest.mediaGroups.AUDIO) {
          Object.entries(parsedManifest.mediaGroups.AUDIO).forEach(([groupId, groupContent]) => {
            Object.entries(groupContent).forEach(([audioName, audioNameContent]) => {
              if (audioNameContent.uri && audioNameContent.uri !== '') {
                if (childPlaylistGroup[`audio:${groupId}:${audioName}`])  {
                  childPlaylistGroup[`audio:${groupId}:${audioName}`].push(audioNameContent.uri)
                } else {
                  childPlaylistGroup[`audio:${groupId}:${audioName}`] = [audioNameContent.uri]
                }
              }
            })
          })
        } */

        // put audio uri with same group together
        if (parsedManifest.mediaGroups && parsedManifest.mediaGroups.AUDIO) {
          Object.entries(parsedManifest.mediaGroups.AUDIO).forEach(([groupId, groupContent]) => {
            Object.entries(groupContent).forEach(([audioName, audioNameContent]) => {
              if (audioNameContent.uri && audioNameContent.uri !== '') {
                if (audioChildPlaylistGroup[`audio:${groupId}`]) {
                  audioChildPlaylistGroup[`audio:${groupId}`].push(audioNameContent.uri);
                } else {
                  audioChildPlaylistGroup[`audio:${groupId}`] = [audioNameContent.uri];
                }
              }
            });
          });
          if (Object.keys(audioChildPlaylistGroup).length > 0) {
            requireCheckAudio = true;
          }
        }

        if (requireCheckAudio) {
          // check audio playlist first
          let audioGroupPromiseList = [];
          Object.entries(audioChildPlaylistGroup).forEach(([key, value]) => {
            let audioGroupChildPromiseList = [];
            value.forEach((uri) => {
              let _uri = generateFullUrl(uri, playbackUrl);
              let audioGroupChildPromise = new Promise((_resolve, _reject) => {
                axios({
                  method: 'get',
                  url: _uri,
                })
                  .then(() => {
                    _resolve(true);
                  })
                  .catch((e) => {
                    _reject(e);
                  });
              });
              audioGroupChildPromiseList.push(audioGroupChildPromise);
            });

            let audioGroupPromise = new Promise((_resolve, _reject) => {
              Promise.all(audioGroupChildPromiseList)
                .then(() => {
                  _resolve([key, true]);
                })
                .catch(() => {
                  _resolve([key, false]);
                });
            });
            audioGroupPromiseList.push(audioGroupPromise);
          });
          Promise.all(audioGroupPromiseList).then((results) => {
            // check video playlist after finished checking audio playlist
            let checkAudioResult = {};
            results.forEach((result) => {
              checkAudioResult[result[0]] = result[1];
            });

            // put video uri with same height to a group
            if (parsedManifest.playlists) {
              parsedManifest.playlists.forEach((child) => {
                if (child.uri && child.uri !== '') {
                  if (
                    child.attributes &&
                    child.attributes.RESOLUTION &&
                    child.attributes.RESOLUTION.height
                  ) {
                    let requestUri = child.uri;
                    if (child.attributes.AUDIO && child.attributes.AUDIO !== '') {
                      // to prevent blocking in case failed to get the audio group
                      if (checkAudioResult[`audio:${child.attributes.AUDIO}`] === false) {
                        // special string to fail the checking if audio not ok
                        requestUri = 'fail this uri';
                      }
                    }
                    if (childPlaylistGroup[`video:${child.attributes.RESOLUTION.height}`]) {
                      childPlaylistGroup[`video:${child.attributes.RESOLUTION.height}`].push(
                        requestUri
                      );
                    } else {
                      childPlaylistGroup[`video:${child.attributes.RESOLUTION.height}`] = [
                        requestUri,
                      ];
                    }
                  }
                }
              });
            }

            if (Object.keys(childPlaylistGroup).length > 0) {
              let promiseList = [];
              Object.entries(childPlaylistGroup).forEach(([, value]) => {
                let childPlaylistGroupPromise = [];
                value.forEach((uri) => {
                  if (uri === 'fail this uri') {
                    // directly fail for special string
                    let childPlaylistPromise = new Promise((_resolve, _reject) => {
                      _resolve('audio failed');
                    });
                    childPlaylistGroupPromise.push(childPlaylistPromise);
                  } else {
                    let _uri = generateFullUrl(uri, playbackUrl);
                    let childPlaylistPromise = new Promise((_resolve, _reject) => {
                      axios({
                        method: 'get',
                        url: _uri,
                      })
                        // handle Promise.any not support in older browsers
                        /* .then(() => {
                            _resolve(true)
                          })
                          .catch((e) => {
                            _reject(e)
                          }) */
                        .then(() => {
                          // in order to make the next Promise.all can finish immediately
                          // for any success case, reject success case
                          _reject(true);
                        })
                        .catch((e) => {
                          _resolve(e);
                        });
                    });
                    childPlaylistGroupPromise.push(childPlaylistPromise);
                  }
                });
                let childPlayListPromise = new Promise((_resolve, _reject) => {
                  // handle Promise.any not support in some browsers
                  /* Promise.any(childPlaylistGroupPromise)
                      .then(() => {
                        _resolve(true)
                      })
                      .catch((e) => {
                        _reject(e)
                      }) */
                  Promise.all(childPlaylistGroupPromise)
                    .then((e) => {
                      // mean failed to get any child m3u8
                      _reject(e);
                    })
                    .catch(() => {
                      // mean succeeded to get at least one child m3u8
                      _resolve(true);
                    });
                });
                promiseList.push(childPlayListPromise);
              });

              Promise.all(promiseList)
                .then(() => {
                  resolve();
                })
                .catch((e) => {
                  console.error(e);
                  reject();
                });
            } else {
              reject();
            }
          });
        } else {
          // put video uri with same height to a group
          if (parsedManifest.playlists) {
            parsedManifest.playlists.forEach((child) => {
              if (child.uri && child.uri !== '') {
                if (
                  child.attributes &&
                  child.attributes.RESOLUTION &&
                  child.attributes.RESOLUTION.height
                ) {
                  if (childPlaylistGroup[`video:${child.attributes.RESOLUTION.height}`]) {
                    childPlaylistGroup[`video:${child.attributes.RESOLUTION.height}`].push(
                      child.uri
                    );
                  } else {
                    childPlaylistGroup[`video:${child.attributes.RESOLUTION.height}`] = [child.uri];
                  }
                }
              }
            });
          }

          if (Object.keys(childPlaylistGroup).length > 0) {
            let promiseList = [];
            Object.entries(childPlaylistGroup).forEach(([, value]) => {
              let childPlaylistGroupPromise = [];
              value.forEach((uri) => {
                let _uri = generateFullUrl(uri, playbackUrl);
                let childPlaylistPromise = new Promise((_resolve, _reject) => {
                  axios({
                    method: 'get',
                    url: _uri,
                  })
                    // handle Promise.any not support in older browsers
                    /* .then(() => {
                      _resolve(true)
                    })
                    .catch((e) => {
                      _reject(e)
                    }) */
                    .then(() => {
                      // in order to make the next Promise.all can finish immediately
                      // for any success case, reject success case
                      _reject(true);
                    })
                    .catch((e) => {
                      _resolve(e);
                    });
                });
                childPlaylistGroupPromise.push(childPlaylistPromise);
              });
              let childPlayListPromise = new Promise((_resolve, _reject) => {
                // handle Promise.any not support in some browsers
                /* Promise.any(childPlaylistGroupPromise)
                  .then(() => {
                    _resolve(true)
                  })
                  .catch((e) => {
                    _reject(e)
                  }) */
                Promise.all(childPlaylistGroupPromise)
                  .then((e) => {
                    // mean failed to get any child m3u8
                    _reject(e);
                  })
                  .catch(() => {
                    // mean succeeded to get at least one child m3u8
                    _resolve(true);
                  });
              });
              promiseList.push(childPlayListPromise);
            });

            Promise.all(promiseList)
              .then(() => {
                resolve();
              })
              .catch((e) => {
                console.error(e);
                reject();
              });
          } else {
            reject();
          }
        }
      })
      .catch(() => {
        reject();
      });
  });
}
