import React, { useState, useEffect, Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getMultiLocaleContent } from 'utils/helper';

import LocaleSelector from 'components/localeSelector';

import logo from 'images/logo.png';
import logoEn from 'images/header_logo_en.png';
import logoSc from 'images/header_logo_sc.png';
import logoTc from 'images/header_logo_tc.png';
import logoEn2x from 'images/header_logo_en@2x.png';
import logoSc2x from 'images/header_logo_sc@2x.png';
import logoTc2x from 'images/header_logo_tc@2x.png';
import beianLogo from 'images/beian.png';

export default function Layout(props) {
  const { customConfig, eventValidated, exitHandler, loginHandler, logoutHandler } = props;
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { requireLogin, requirePasscode } = useSelector((state) => state.event);
  const { t, i18n } = useTranslation();
  const [logoImage, setLogoImage] = useState(logoEn);

  useEffect(() => {
    if (i18n.language === 'zh-Hans') {
      setLogoImage([logoSc, logoSc2x]);
    } else if (i18n.language === 'zh-Hant') {
      setLogoImage([logoTc, logoTc2x]);
    } else {
      setLogoImage([logoEn, logoEn2x]);
    }
  }, [i18n.language]);

  return (
    <React.Fragment>
      <header className="app-header">
        <div className={`header-wrapper ${customConfig.restrictHeaderWidth ? 'restrict' : ''}`}>
          {customConfig.logoImageUrl ? (
            <a href={customConfig.logoLink} className="custom-logo-wrapper" to="/">
              <img
                src={customConfig.logoImageUrl}
                className={`app-logo ${customConfig.logoImageUrlMobile ? 'desktop' : ''}`}
                alt="logo"
              />
              {customConfig.logoImageUrlMobile && (
                <img src={customConfig.logoImageUrlMobile} className="app-logo mobile" alt="logo" />
              )}
            </a>
          ) : (
            <a href="https://www.oneclickgolive.com" className="logo-wrapper" to="/">
              <img
                srcSet={`${logoImage[0]}, ${logoImage[1]} 2x`}
                src={logoImage[0]}
                className="app-logo"
                alt="logo"
              />
            </a>
          )}
          <div className="header-function-container">
            {/* <Link to="/event/a">Test</Link> or*/}
            {customConfig.enableLocale && (
              <LocaleSelector availableLocales={customConfig.availableLocales} />
            )}
            {eventValidated && (
              <button className="function" onClick={exitHandler}>
                {t('app.exit')}
              </button>
            )}
            {!requirePasscode && requireLogin && (
              <Fragment>
                {isLoggedIn && (
                  <button className="function" onClick={logoutHandler}>
                    {t('app.logout-btn')}
                  </button>
                )}
                {!isLoggedIn && (
                  <button className="function" onClick={loginHandler}>
                    {t('app.login-btn')}
                  </button>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </header>
      <Outlet />
      <footer className="app-footer">
        {!customConfig.hideFooter && (
          <React.Fragment>
            {customConfig.footerImageUrl || customConfig.footerText ? (
              <a href={customConfig.footerLink} className="link">
                {customConfig.footerImageUrl && (
                  <img className="logo" src={customConfig.footerImageUrl} alt="Logo"></img>
                )}
                {customConfig.footerText && (
                  <span className="text">
                    {getMultiLocaleContent(customConfig.footerText, i18n.language)}
                  </span>
                )}
              </a>
            ) : customConfig.hideFooterLink ? (
              <div className="link">
                <img className="logo" src={logo} alt="Logo"></img>
                <span className="text">{t('app.footer')}</span>
              </div>
            ) : (
              <a href="https://www.oneclickgolive.com" className="link">
                <img className="logo" src={logo} alt="Logo"></img>
                <span className="text">{t('app.footer')}</span>
              </a>
            )}
          </React.Fragment>
        )}
        {process.env.REACT_APP_IS_CN === '1' && (
          <div className="icp">
            <a href="http://beian.miit.gov.cn/" rel="noreferrer" target="_blank">
              京ICP备16066699号-2
            </a>
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502051708"
              rel="noreferrer"
              target="_blank"
            >
              <img src={beianLogo} style={{ height: '20px', width: '20px' }} alt="beianLogo" />
              京公网安备 11010502051708号
            </a>
          </div>
        )}
      </footer>
    </React.Fragment>
  );
}
