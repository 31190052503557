import { Box, Dialog, Fade } from '@mui/material';
import dayjs from 'dayjs';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMultiLocaleContent } from 'utils/helper';
import { mainColor, secondColor } from './theme';
import _ from 'lodash';
import { getEventV2 } from 'api/baseAPI';
import logger from 'utils/loggerUtils';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const defaultStyle = {
  backgroundColor: {
    normal: '#FFFFFF1A',
    current: mainColor,
  },
  textColor: {
    normal: '#FFFFFF',
    current: secondColor,
  },
  closeButton: {
    textColor: '#FFFFFF',
    bgColor: mainColor,
  },
  enableMobilePopUp: true,
  popUp: {
    textColor: '#FFFFFF',
    bgColor: secondColor,
  },
};

const EventDatetimeDisplay = (props) => {
  const { hideDate, startDatetime, endDatetime } = props;
  const { i18n } = useTranslation();

  const startMonthDisplay = () => {
    if (startDatetime) {
      if (i18n.language === 'zh-Hans' || i18n.language === 'zh-Hant') {
        return dayjs(startDatetime).format('M月');
      }
      return dayjs(startDatetime).format('MMM ');
    }
    return '';
  };

  const startDayDisplay = () => {
    if (startDatetime) {
      if (i18n.language === 'zh-Hans' || i18n.language === 'zh-Hant') {
        return dayjs(startDatetime).format('D日');
      }
      return dayjs(startDatetime).format('DD');
    }
    return '';
  };

  const endMonthDisplay = () => {
    if (endDatetime) {
      if (i18n.language === 'zh-Hans' || i18n.language === 'zh-Hant') {
        return dayjs(endDatetime).format('M月');
      }
      return dayjs(endDatetime).format('MMM ');
    }
    return '';
  };

  const endDayDisplay = () => {
    if (endDatetime) {
      if (i18n.language === 'zh-Hans' || i18n.language === 'zh-Hant') {
        return dayjs(endDatetime).format('D日');
      }
      return dayjs(endDatetime).format('DD');
    }
    return '';
  };

  const startTimeDisplay = () => {
    if (startDatetime) {
      return dayjs(startDatetime).format('HH:mm');
    }
    return '';
  };

  const endTimeDisplay = () => {
    if (endDatetime) {
      return dayjs(endDatetime).format('HH:mm');
    }
    return '';
  };

  return (
    <Box sx={{ fontWeight: 'bold' }}>
      {!hideDate && <Box component="span">{`${startMonthDisplay()}${startDayDisplay()} `}</Box>}
      <Box component="span">{`${startTimeDisplay()}`}</Box>
      <Box component="span">{` - `}</Box>
      {(startMonthDisplay() !== endMonthDisplay() || startDayDisplay() !== endDayDisplay()) &&
        !hideDate && <Box component="span">{`${endMonthDisplay()}${endDayDisplay()} `}</Box>}
      <Box component="span">{`${endTimeDisplay()}`}</Box>
    </Box>
  );
};

const isCurrentTimeBetween = (startISOString, endISOString, currentDateTime) => {
  const currentTime = dayjs(currentDateTime); // Get the current time
  const startTime = dayjs(startISOString);
  const endTime = dayjs(endISOString);

  return currentTime.isAfter(startTime) && currentTime.isBefore(endTime);
};

const StatusDisplay = (props) => {
  const { startDatetime, endDatetime } = props;
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ ml: '8px' }}>
      {isCurrentTimeBetween(startDatetime, endDatetime, currentTime) && (
        <Box sx={{ backgroundColor: '#FF5A62', borderRadius: '4px', color: '#FFFFFF', px: '4px' }}>
          LIVE
        </Box>
      )}
    </Box>
  );
};

export const ScheduleItem = (props) => {
  const { currentId, eventId, hideDate, onEventClick, scrollTo, style } = props;
  const { i18n } = useTranslation();
  const [initialized, setInitialized] = useState(false);
  const [event, setEvent] = useState({});

  useEffect(() => {
    if (eventId && !initialized) {
      getEventV2(eventId)
        .then((response) => {
          if (response && response.data && typeof response.data === 'object') {
            setEvent(response.data);
            setInitialized(true);
            if (document.getElementById(currentId)) {
              scrollTo(document.getElementById(currentId).offsetTop - 80);
            }
          } else {
            throw new Error('Invalid Event');
          }
        })
        .catch((err) => {
          logger.error(err);
        });
    }
  }, [currentId, eventId, initialized, scrollTo]);

  return (
    <Fade in={initialized}>
      {initialized ? (
        <Box
          id={eventId}
          sx={{
            backgroundColor:
              currentId === eventId ? style.backgroundColor.current : style.backgroundColor.normal,
            borderRadius: '8px',
            color: currentId === eventId ? style.textColor.current : style.textColor.normal,
            lineHeight: '1.5',
            m: '20px',
            p: '8px',
            textAlign: 'left',
            cursor: 'pointer',
          }}
          onClick={() => {
            onEventClick(eventId);
          }}
        >
          <Box sx={{ display: 'flex', mb: '8px' }}>
            <EventDatetimeDisplay
              hideDate={hideDate}
              startDatetime={event.estimatedStartDatetime}
              endDatetime={event.estimatedEndDatetime}
            />
            <StatusDisplay
              startDatetime={event.estimatedStartDatetime}
              endDatetime={event.estimatedEndDatetime}
            />
          </Box>
          <Box sx={{ mb: '8px' }}>{getMultiLocaleContent(event.title, i18n.language)}</Box>
        </Box>
      ) : (
        <Box />
      )}
    </Fade>
  );
};

export const Schedule = (props) => {
  const {
    currentId,
    currentStatus,
    displaying,
    eventList,
    hideDate,
    onNext,
    onEventClick,
    scrollTo,
    customStyle,
    openPopUpIndicator,
  } = props;
  const { t } = useTranslation();
  const matches = useMediaQuery('(min-width:768px)');
  const statusRef = useRef(null);
  const [style, setStyle] = useState(defaultStyle);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setStyle(_.merge({}, defaultStyle, customStyle));
  }, [customStyle]);

  useEffect(() => {
    if (currentStatus !== statusRef.current) {
      if (statusRef.current === 'live' && currentStatus && currentStatus !== 'live') {
        const currentEvent = eventList.find((e) => e.id === currentId);
        if (currentEvent && currentEvent.next) {
          onNext(currentEvent.next);
        }
      }
      statusRef.current = currentStatus;
    }
  }, [currentId, currentStatus, eventList, onNext]);

  useEffect(() => {
    if (document.getElementById(currentId) && displaying) {
      scrollTo(document.getElementById(currentId).offsetTop - 80);
    }
  }, [currentId, displaying, eventList, scrollTo]);

  useEffect(() => {
    if (openPopUpIndicator !== 0) {
      setOpened(true);
    }
  }, [openPopUpIndicator]);

  const eventClickHandler = (e) => {
    onEventClick(e);
    closeSchedule();
  };

  const openSchedule = (e) => {
    setOpened(true);
  };

  const closeSchedule = (e) => {
    setOpened(false);
  };

  const dialogScrollTo = useCallback((top) => {
    document.getElementById('schedule-dialog').scroll({ top: top, behavior: 'smooth' });
  }, []);

  return (
    <Fragment>
      {matches || !style.enableMobilePopUp ? (
        <Box sx={{ position: 'relative' }}>
          {eventList &&
            eventList.map((event) => (
              <ScheduleItem
                key={event.id}
                eventId={event.id}
                currentId={currentId}
                hideDate={hideDate}
                onEventClick={onEventClick}
                scrollTo={scrollTo}
                style={style}
              />
            ))}
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              color: style.popUp.textColor,
              display: 'flex',
              fontSize: '20px',
              fontWeight: 'bold',
              padding: '10px',
              textAlign: 'left',
              cursor: 'pointer',
            }}
            onClick={openSchedule}
          >
            {t('event.schedule')}
            <ArrowDropDownIcon />
          </Box>
          {opened && (
            <Dialog
              open={opened}
              PaperProps={{ id: 'schedule-dialog' }}
              sx={{
                '.MuiPaper-root': {
                  backgroundColor: style.popUp.bgColor,
                  width: '100vw',
                  height: 'var(--app-height)',
                  maxWidth: 'none',
                  maxHeight: 'none',
                  m: 0,
                  justifyContent: 'space-between',
                },
              }}
            >
              <Box>
                <Box
                  sx={{
                    color: style.popUp.textColor,
                    m: '20px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  {t('event.schedule')}
                </Box>
                {eventList &&
                  eventList.map((event) => (
                    <ScheduleItem
                      key={event.id}
                      eventId={event.id}
                      currentId={currentId}
                      hideDate={hideDate}
                      onEventClick={eventClickHandler}
                      scrollTo={dialogScrollTo}
                      style={style}
                    />
                  ))}
              </Box>
              <Box
                sx={{
                  m: '20px',
                  button: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    padding: '16px',
                    fontSize: '16px',
                    color: style.closeButton.textColor,
                    background: style.closeButton.bgColor,
                    border: 0,
                    borderRadius: '5px',
                    cursor: 'pointer',
                  },
                }}
              >
                <button onClick={closeSchedule}>{t('app.close')}</button>
              </Box>
            </Dialog>
          )}
        </Box>
      )}
    </Fragment>
  );
};
