import logger from 'utils/loggerUtils';

// Method copied from https://www.theothis.player.com/test-your-stream-hls-dash-hesp on 2023-04-22. 10:34:32AM +8UTC
class TheoTestStreamUtils {
  constructor(player) {
    this.akamaiTime = new Date().getTime();
    this.timeWhenAkamaiRequest = performance.now();
    this.player = player;
    this.requestAkamaiTime();
  }

  calculateCurrentProgramDt() {
    // New function to grab playback time, convert to ms
    var currentProgramDateTime = this.player.currentProgramDateTime;
    if (!currentProgramDateTime || isNaN(currentProgramDateTime)) {
      return null;
    }
    return currentProgramDateTime;
  }

  requestAkamaiTime() {
    fetch('http://time.akamai.com/?iso&ms')
      .then((response) => response.text())
      .then((data) => {
        const date = new Date(data);
        this.akamaiTime = date.getTime();
        this.timeWhenAkamaiRequest = performance.now();
      })
      .catch((error) => {
        // console.error('Error:', error);
      });
  }

  calculateCurrentTimestamp() {
    const diff = performance.now() - this.timeWhenAkamaiRequest;
    return new Date(this.akamaiTime + diff);
  }

  calculateLatency() {
    // return difference current time and playback time
    var hespLatency = ((this.player.hesp && this.player.hesp.currentLatency) || 0) * 1000;
    if (hespLatency) {
      return hespLatency;
    }

    var now = this.calculateCurrentTimestamp();
    var playbackTime = this.calculateCurrentProgramDt();
    if (!playbackTime) {
      return -1;
    }
    // console.log('now', now);
    // console.log('playbackTime', playbackTime);
    return now.getTime() - playbackTime.getTime();
  }

  calculateBufferSize() {
    var MARGIN = 0.04;
    var buffered = this.player.buffered;
    var currentTime = this.player.currentTime;

    var searchingStartIndex = true;
    var bufferSize = 0;
    var bufferedCalculationEnd = 0;
    for (var i = 0; i < buffered.length; i += 1) {
      var start = buffered.start(i) - MARGIN;
      var end = buffered.end(i) + MARGIN;

      if (searchingStartIndex) {
        if (start < currentTime) {
          searchingStartIndex = false;
          bufferSize = Math.max(0, end - currentTime);
        }
      } else {
        if (bufferedCalculationEnd > start) {
          bufferSize = Math.max(0, end - currentTime);
        } else {
          return bufferSize;
        }
      }
      bufferedCalculationEnd = end;
    }

    return bufferSize;
  }

  calculatePlayedBandwidth() {
    return (
      (this.player.videoTracks[0].activeQuality &&
        this.player.videoTracks[0].activeQuality.bandwidth) ||
      0
    );
  }

  calculateMeasuredAccurateBandwidth() {
    return this.player.metrics.currentBandwidthEstimate || this.player.network.estimator.bandwidth;
  }

  formatInMiliseconds(value) {
    return Math.floor(value) + 'ms';
  }

  formatDateTime(date) {
    if (!date) {
      return 'Not available';
    }
    return date.toISOString();
  }
}

const seekToLatestForLive = (player) => {
  if (!player) {
    return;
  }
  if (player.duration === 1 / 0) {
    player.currentTime = player.seekable.end(0);
    logger.info('[theoPlayerUtils][documentVisible][seekToLatestForLive]');
  }
};

export { TheoTestStreamUtils, seekToLatestForLive };
