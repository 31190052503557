import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import Player from 'components/player';
import PasscodePage from 'components/passcodePage';
import { useEventManipulate } from './useEventManipulate';
import { getMultiLocaleContent, logoutHandler } from 'utils/helper';
import { DialogContext } from 'components/dialogContext';
import NotFound from 'components/notFound';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';
import NativeSelect from '@mui/material/NativeSelect';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BallotIcon from '@mui/icons-material/Ballot';
import ErrorIcon from '@mui/icons-material/Error';

import 'styles/scss/routes/event.scss';

function EventDatetimeDisplay(props) {
  const { startDatetime, endDatetime } = props;
  const { i18n } = useTranslation();

  const startMonthDisplay = () => {
    if (startDatetime) {
      if (i18n.language === 'zh-Hans' || i18n.language === 'zh-Hant') {
        return dayjs(startDatetime).format('M月');
      }
      return dayjs(startDatetime).format('MMM');
    }
    return '';
  };

  const startDayDisplay = () => {
    if (startDatetime) {
      if (i18n.language === 'zh-Hans' || i18n.language === 'zh-Hant') {
        return dayjs(startDatetime).format('D日');
      }
      return dayjs(startDatetime).format('DD');
    }
    return '';
  };

  const endMonthDisplay = () => {
    if (endDatetime) {
      if (i18n.language === 'zh-Hans' || i18n.language === 'zh-Hant') {
        return dayjs(endDatetime).format('M月');
      }
      return dayjs(endDatetime).format('MMM');
    }
    return '';
  };

  const endDayDisplay = () => {
    if (endDatetime) {
      if (i18n.language === 'zh-Hans' || i18n.language === 'zh-Hant') {
        return dayjs(endDatetime).format('D日');
      }
      return dayjs(endDatetime).format('DD');
    }
    return '';
  };

  const startTimeDisplay = () => {
    if (startDatetime) {
      return dayjs(startDatetime).format('HH:mm');
    }
    return '';
  };

  const endTimeDisplay = () => {
    if (endDatetime) {
      return dayjs(endDatetime).format('HH:mm');
    }
    return '';
  };

  return (
    <div className="time">
      <div className="start-date">
        <span className="month">{`${startMonthDisplay()}`}</span>
        <span className="day">{`${startDayDisplay()}`}</span>
      </div>
      <span className="start-time">{`${startTimeDisplay()}`}</span>
      <span>-</span>
      {(startMonthDisplay() !== endMonthDisplay() || startDayDisplay() !== endDayDisplay()) && (
        <div className="end-date">
          <span className="month">{`${endMonthDisplay()}`}</span>
          <span className="day">{`${endDayDisplay()}`}</span>
        </div>
      )}
      <span className="end-time">{`${endTimeDisplay()}`}</span>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {<div className="tab-content">{children}</div>}
    </div>
  );
}

function AudioTab(props) {
  const { children } = props;

  return <div className="audio-selector-wrapper">{children}</div>;
}

function HLPolling(props) {
  const { customConfig, passcode, passcodeEnabled, src } = props;
  const { i18n } = useTranslation();
  const [iframeStyle, setIframeStyle] = useState({});
  const [wrapperStyle, setWrapperStyle] = useState({});
  const [height, setHeight] = useState(0);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const iframeRef = useRef(null);

  const postMessageHandler = useCallback((e) => {
    let iframeDomain = `${process.env.REACT_APP_HL_POLLING_BASE_URL}`;
    if (e.origin === iframeDomain) {
      if (e.data.event === 'HEIGHT_CHANGE') {
        if (e.data.data.height === 0) {
          setHeight(0);
        } else {
          setHeight(e.data.data.height + 20);
        }
      }
    }
  }, []);

  const iframeLoadHandler = (e) => {
    setIframeLoaded(true);
    let iframeDomain = `${process.env.REACT_APP_HL_POLLING_BASE_URL}`;
    let locale = 'en';
    let localeMapping = {
      en: 'en',
      'zh-Hans': 'zh-hans',
      'zh-Hant': 'zh-hant',
    };
    if (i18n.language) {
      locale = localeMapping[i18n.language];
    }
    let voter = `anonymous_${(Math.random() + 1).toString(36).substring(7)}`;
    if (passcodeEnabled && passcode) {
      voter = passcode;
    }

    iframeRef.current.contentWindow.postMessage(
      {
        event: 'SETUP',
        data: {
          locale: locale,
          theme: {
            primary: customConfig.pollingThemeColor,
            textColor: '#000000',
            button: {
              textColor: '#ffffff',
              backgroundColor: customConfig.pollingThemeColor,
            },
          },
          voter: voter,
        },
      },
      iframeDomain
    );
  };

  useEffect(() => {
    if (height === 0) {
      setWrapperStyle({ padding: '0px' });
      setIframeStyle({ height: '0px', padding: '0px' });
    } else {
      setWrapperStyle({ padding: '20px' });
      setIframeStyle({ height: height + 'px', padding: '0px' });
    }
  }, [height]);

  useEffect(() => {
    window.addEventListener('message', postMessageHandler, false);

    return () => {
      window.removeEventListener('message', postMessageHandler);
    };
  }, [postMessageHandler]);

  useEffect(() => {
    if (iframeLoaded) {
      let iframeDomain = `${process.env.REACT_APP_HL_POLLING_BASE_URL}`;
      let locale = 'en';
      let localeMapping = {
        en: 'en',
        'zh-Hans': 'zh-hans',
        'zh-Hant': 'zh-hant',
      };
      if (i18n.language) {
        locale = localeMapping[i18n.language];
      }
      iframeRef.current.contentWindow.postMessage(
        {
          event: 'SET_LOCALE',
          data: { locale: locale },
        },
        iframeDomain
      );
    }
  }, [i18n.language, iframeLoaded]);

  return (
    <div className="polling-wrapper" style={wrapperStyle}>
      <iframe
        id="polling-iframe"
        title="polling"
        className="polling-iframe"
        frameBorder="0"
        ref={iframeRef}
        style={iframeStyle}
        src={src}
        onLoad={iframeLoadHandler}
      ></iframe>
    </div>
  );
}

function ParrotPolling(props) {
  const { id, src, className } = props;
  const { i18n } = useTranslation();
  const [style, setStyle] = useState({});
  const [height, setHeight] = useState(0);
  const [isTablet, setIsTablet] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const iframeRef = useRef(null);

  const postMessageHandler = useCallback(
    (e) => {
      let iframeDomain = `${process.env.REACT_APP_PARROT_CLIENT_BASE_URL}`;
      if (e.origin === iframeDomain) {
        if (e.data.action === 'HEIGHT:CHANGE') {
          if (e.data.content.value === 0) {
            setHeight(0);
          } else {
            setHeight(e.data.content.value);
          }
        }
        if (e.data.action === 'INITIALIZATION:DONE') {
          setIframeLoaded(true);
          let locale = 'en';
          if (i18n.language) {
            locale = i18n.language;
          }
          iframeRef.current.contentWindow.postMessage(
            {
              action: 'LOCALE:CHANGE',
              content: {
                value: locale,
              },
            },
            iframeDomain
          );
        }
      }
    },
    [i18n.language]
  );

  useEffect(() => {
    window.addEventListener('message', postMessageHandler, false);

    return () => {
      window.removeEventListener('message', postMessageHandler);
    };
  }, [postMessageHandler]);

  useEffect(() => {
    const matchTabletSizeHandler = (e) => {
      setIsTablet(e.matches);
    };
    const matchTabletQueryList = window.matchMedia(`(min-width: 768px)`);
    matchTabletSizeHandler(matchTabletQueryList);

    if (matchTabletQueryList.addEventListener) {
      matchTabletQueryList.addEventListener('change', matchTabletSizeHandler);
    } else {
      matchTabletQueryList.addListener(matchTabletSizeHandler);
    }

    return () => {
      if (matchTabletQueryList.removeEventListener) {
        matchTabletQueryList.removeEventListener('change', matchTabletSizeHandler);
      } else {
        matchTabletQueryList.removeListener(matchTabletSizeHandler);
      }
    };
  }, []);

  useEffect(() => {
    if (!isTablet) {
      setStyle({ height: `${height}px` });
    } else {
      setStyle({});
    }
  }, [height, isTablet]);

  useEffect(() => {
    if (iframeLoaded) {
      let iframeDomain = `${process.env.REACT_APP_PARROT_CLIENT_BASE_URL}`;
      let locale = 'en';
      if (i18n.language) {
        locale = i18n.language;
      }
      iframeRef.current.contentWindow.postMessage(
        {
          action: 'LOCALE:CHANGE',
          content: {
            value: locale,
          },
        },
        iframeDomain
      );
    }
  }, [i18n.language, iframeLoaded]);

  return (
    <iframe
      id={id}
      src={src}
      className={className}
      ref={iframeRef}
      frameBorder="0"
      title="polling"
      style={style}
    ></iframe>
  );
}

export default function Event(props) {
  const params = useParams();
  const { i18n, t } = useTranslation();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { browserSessionIdentifier } = useSelector((state) => state.app);
  const {
    backgroundImage,
    isReady,
    isDrm,
    isLive,
    isVod,
    playerProblemHandler,
    streamId,
    event,
    stream,
    title,
    description,
    detailDescription,
    questionEnabled,
    isHLQuestion,
    slidoQuestionLink,
    question,
    questionSubmitHandler,
    questionChangeHandler,
    questionSubmitting,
    pollingEnabled,
    isHLPolling,
    parrotPollingLink,
    slidoPollingLink,
    audioReference,
    customStyle,
    customConfig,
    selectedTab,
    tabChangeHandler,
    displayPasscodePage,
    passcode,
    passcodeEnabled,
    passcodeValidating,
    validatePasscodeSuccessHandler,
    audioChangeHandler,
    getDisplayMessage,
    liveDuration,
    requireLogin,
    loginValidated,
    sessionKicked,
    validateLoginEmail,
    notFound,
  } = useEventManipulate(props);
  const { openLogin, closeLogin } = useContext(DialogContext);
  /* can remove after tested HL polling or will not use HL polling forever
  const [pollingIframeLoaded, setPollingIframeLoaded] = useState(false)
  const [pollingIframeHeight, setPollingIframeHeight] = useState(0)
  */

  const isDisplayQATab = () => {
    return questionEnabled() && slidoQuestionLink() !== null && !isHLQuestion();
  };

  const isDisplayPollingTab = () => {
    return pollingEnabled() && !isHLPolling();
  };

  const isDisplayAudioTab = () => {
    return audioReference() !== null && !customConfig.selectChannelAtBottom;
  };

  const isDisplayTab = () => {
    return isDisplayQATab() || isDisplayPollingTab() || isDisplayAudioTab();
  };

  const hlPollingLink = useCallback(() => {
    return `${process.env.REACT_APP_HL_POLLING_BASE_URL}/embed/${params.eventId}/polling`;
  }, [params.eventId]);

  const loginClickHandler = () => {
    if (!isLoggedIn) {
      openLogin(
        () => {
          closeLogin();
        },
        () => {
          closeLogin();
        }
      );
    }
  };

  const logoutClickHandler = () => {
    logoutHandler();
  };

  /* can remove after tested HL polling or will not use HL polling forever
  const pollingIframeLoadedHandler = (e) => {
    setPollingIframeLoaded(true)
    let iframeDomain = `${process.env.REACT_APP_PARROT_CLIENT_BASE_URL}`
    let frame = document.getElementById('polling-iframe')
    if (!frame) {
      return
    }
    let locale = 'en'
    if (isHLPolling()) {
      iframeDomain = `${process.env.REACT_APP_HL_POLLING_BASE_URL}`
      let localeMapping = {
        'en': 'en',
        'zh-Hans': 'zh-hans',
        'zh-Hant': 'zh-hant'
      }
      if (i18n.language) {
        locale = localeMapping[i18n.language]
      }
      let voter = `anonymous_${(Math.random() + 1).toString(36).substring(7)}`
      if (passcodeEnabled && passcode) {
        voter = passcode
      }
  
      frame.contentWindow.postMessage(
        {
          event: 'SETUP',
          data: {
            locale: locale,
            theme: {
              primary: customConfig.pollingThemeColor,
              textColor: '#000000',
              button: {
                textColor: '#ffffff',
                backgroundColor: customConfig.pollingThemeColor
              }
            },
            voter: voter
          }
        },
        iframeDomain
      )
    }
  }

  const postMessageHandler = useCallback((e) => {
    if (pollingEnabled()) {
      let iframeDomain = `${process.env.REACT_APP_PARROT_CLIENT_BASE_URL}`
      if (isHLPolling()) {
        iframeDomain = `${process.env.REACT_APP_HL_POLLING_BASE_URL}`
        if (e.origin === iframeDomain) {
          if (e.data.event === 'HEIGHT_CHANGE') {
            if (e.data.data.height === 0) {
              setPollingIframeHeight(0)
            } else {
              setPollingIframeHeight(e.data.data.height + 20)
            }
          }
        }
      } else {
        if (e.origin === iframeDomain) {
          if (e.data.action === 'HEIGHT:CHANGE') {
            if (e.data.content.value === 0) {
              setPollingIframeHeight(0)
            } else {
              setPollingIframeHeight(e.data.content.value)
            }
          }
          if (e.data.action === 'INITIALIZATION:DONE') {
            setPollingIframeLoaded(true)
            let locale = 'en'
            if (i18n.language) {
              locale = i18n.language
            }
            let frame = document.getElementById('polling-iframe')
            frame.contentWindow.postMessage({
              action: 'LOCALE:CHANGE',
              content: {
                value: locale
              }
            }, iframeDomain)
          }
        }
      }
    }
  }, [i18n.language, isHLPolling, pollingEnabled])

  const pollingWrapperStyle = () => {
    if (pollingIframeHeight === 0) {
      return { 'padding': '0px' }
    } else {
      return { 'padding': '20px' }
    }
  }

  const pollingIframeStyle = () => {
    if (pollingIframeHeight === 0) {
      return { 'height': pollingIframeHeight + 'px', 'padding': '0px' }
    } else {
      return { 'height': pollingIframeHeight + 'px' }
    }
  }

  useEffect(() => {
    window.addEventListener('message', postMessageHandler, false)

    return () => {
      window.removeEventListener('message', postMessageHandler)
    }
  }, [postMessageHandler])

  useEffect(() => {
    if (pollingIframeLoaded) {
      let frame = document.getElementById('polling-iframe')
      if (!frame) {
        return
      }
      let iframeDomain = `${process.env.REACT_APP_PARROT_CLIENT_BASE_URL}`
      if (isHLPolling()) {
        iframeDomain = `${process.env.REACT_APP_HL_POLLING_BASE_URL}`
        let locale = 'en'
        let localeMapping = {
          'en': 'en',
          'zh-Hans': 'zh-hans',
          'zh-Hant': 'zh-hant'
        }
        if (i18n.language) {
          locale = localeMapping[i18n.language]
        }
        frame.contentWindow.postMessage(
          {
            event: 'SET_LOCALE',
            data: { locale: locale }
          },
          iframeDomain
        )
      } else {
        let locale = 'en'
        if (i18n.language) {
          locale = i18n.language
        }
        frame.contentWindow.postMessage({
          action: 'LOCALE:CHANGE',
          content: {
            value: locale
          }
        }, iframeDomain)
      }
    }
  }, [isHLPolling, i18n.language, pollingIframeLoaded])
  */

  const wrappedValidatePasscodeSuccessHandler = useCallback(
    (validateResponseData, saveCookies) => {
      validatePasscodeSuccessHandler(validateResponseData, event.isStatic, saveCookies);
    },
    [event.isStatic, validatePasscodeSuccessHandler]
  );

  return (
    <Fragment>
      {notFound ? (
        <NotFound />
      ) : (
        <main className="event-page" style={customStyle}>
          {displayPasscodePage() ? (
            <PasscodePage
              eventId={params.eventId}
              title={title()}
              passcodeValidating={passcodeValidating}
              validatePasscodeSuccessHandler={wrappedValidatePasscodeSuccessHandler}
            ></PasscodePage>
          ) : (
            <div>
              <div className="event-container">
                <Player
                  cmcd={{
                    sessionId: browserSessionIdentifier,
                    contentId: params.eventId,
                  }}
                  backgroundImage={backgroundImage()}
                  hlsPlaybackUrl={stream.hlsPlaybackUrl}
                  dashPlaybackUrl={stream.dashPlaybackUrl}
                  isReady={isReady}
                  isDrm={isDrm()}
                  isVod={isVod()}
                  fakeLiveLayout={false}
                  playerProblemHandler={playerProblemHandler}
                  manualTriggerControl={customConfig.manualTriggerControl}
                  ignoreChrome105VjsFix={event.ignoreChrome105VjsFix === true}
                  enableCasting={customConfig.enableCasting === true}
                >
                  {requireLogin && !loginValidated && (
                    <div className="overlay">
                      <ErrorIcon className="overlay-icon" sx={{ color: '#ffffff', fontSize: 50 }} />
                      {isLoggedIn ? (
                        sessionKicked ? (
                          <div className="overlay-message">
                            <div>{t('event.session-kicked-error-message')}</div>
                            <div className="btn-wrapper">
                              <button onClick={validateLoginEmail}>{t('event.view-here')}</button>
                            </div>
                          </div>
                        ) : (
                          <div className="overlay-message">
                            <div>{t('event.playback.error-message')}</div>
                            <div className="btn-wrapper">
                              <button onClick={logoutClickHandler}>{t('app.logout-btn')}</button>
                            </div>
                          </div>
                        )
                      ) : (
                        <div className="overlay-message">
                          <div>{t('event.require-login-message')}</div>
                          <div className="btn-wrapper">
                            <button onClick={loginClickHandler}>{t('app.login-btn')}</button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </Player>
                <div className="side-panel" id="side-panel">
                  <Box id="tabs-container" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    {isDisplayTab() && (
                      <Tabs
                        value={selectedTab}
                        onChange={tabChangeHandler}
                        aria-label="tabs"
                        variant="scrollable"
                        scrollButtons="auto"
                        className="side-panel-tabs"
                      >
                        <Tab
                          className="side-panel-tab"
                          icon={<PersonSearchIcon />}
                          label={t('event.info')}
                          value={'info'}
                        />
                        {isDisplayQATab() && (
                          <Tab
                            className="side-panel-tab"
                            icon={<QuestionAnswerIcon />}
                            label={t('event.q-and-a')}
                            value={'qa'}
                          />
                        )}
                        {isDisplayPollingTab() && (
                          <Tab
                            className="side-panel-tab"
                            icon={<BallotIcon />}
                            label={t('event.polling')}
                            value={'polling'}
                          />
                        )}
                        {streamId && isDisplayAudioTab() && (
                          <AudioTab>
                            <select
                              className="audio-selector"
                              value={streamId}
                              onChange={audioChangeHandler}
                            >
                              {audioReference().map((reference) => (
                                <option value={reference.eventId} key={reference.eventId}>
                                  {getDisplayMessage(
                                    t(`event.si.${reference.language}`),
                                    reference.customDisplayName
                                  )}
                                </option>
                              ))}
                            </select>
                          </AudioTab>
                        )}
                      </Tabs>
                    )}
                  </Box>
                  <div className="tab-content">
                    <TabPanel className="event-info" value={selectedTab} index={'info'}>
                      <EventDatetimeDisplay
                        startDatetime={event.estimatedStartDatetime}
                        endDatetime={event.estimatedEndDatetime}
                      />
                      <div className="title">{title()}</div>
                      {description() && (
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{ __html: description() }}
                        ></div>
                      )}
                      {questionEnabled() && !slidoQuestionLink() && (
                        <div className="question-container">
                          <form onSubmit={questionSubmitHandler}>
                            <textarea
                              className="question-area"
                              placeholder={
                                customConfig.questionBoxPlaceholder
                                  ? getMultiLocaleContent(
                                      customConfig.questionBoxPlaceholder,
                                      i18n.language
                                    )
                                  : t('event.question.placeholder')
                              }
                              value={question}
                              onChange={questionChangeHandler}
                            ></textarea>
                            <button type="submit" className="submit-btn">
                              {questionSubmitting ? (
                                <CircularProgress size={24}></CircularProgress>
                              ) : (
                                <span>{t('event.question.submit')}</span>
                              )}
                            </button>
                          </form>
                        </div>
                      )}
                      {streamId && audioReference() && !isDisplayAudioTab() && (
                        <div className="audio-wrapper">
                          <NativeSelect
                            value={streamId}
                            onChange={audioChangeHandler}
                            inputProps={{
                              name: 'audio-select',
                              id: 'audio-select',
                            }}
                            sx={{
                              width: '100%',
                              backgroundColor: '#ffffff',
                              padding: '0px',
                              fontFamily: 'Lato, Microsoft JhengHei, Helvetica, arial',
                              borderRadius: '5px',
                              color: '#999999',
                              select: {
                                padding: '12px 32px 12px 12px',
                                ':focus': {
                                  backgroundColor: 'initial',
                                },
                              },
                            }}
                          >
                            {audioReference().map((reference) => (
                              <option value={reference.eventId} key={reference.eventId}>
                                {getDisplayMessage(
                                  t(`event.si.${reference.language}`),
                                  reference.customDisplayName
                                )}
                              </option>
                            ))}
                          </NativeSelect>
                        </div>
                      )}
                    </TabPanel>
                    {isDisplayQATab() && (
                      <TabPanel className="qa" value={selectedTab} index={'qa'}>
                        <iframe
                          src={slidoQuestionLink()}
                          height="100%"
                          width="100%"
                          frameBorder="0"
                          title="slido-qa"
                        ></iframe>
                      </TabPanel>
                    )}
                    {isDisplayPollingTab() && (
                      <TabPanel
                        className={`polling${slidoPollingLink() !== null ? ' slido' : ''}`}
                        value={selectedTab}
                        index={'polling'}
                      >
                        {slidoPollingLink() !== null ? (
                          <iframe
                            src={slidoPollingLink()}
                            height="100%"
                            width="100%"
                            frameBorder="0"
                            title="slido-polling"
                          ></iframe>
                        ) : (
                          <ParrotPolling
                            id="polling-iframe"
                            src={parrotPollingLink()}
                            className="polling-iframe"
                          ></ParrotPolling>
                        )}
                      </TabPanel>
                    )}
                  </div>
                </div>
              </div>
              <div className="event-status">
                {isLive() && (
                  <React.Fragment>
                    <span className="live-mark">
                      <span className="dot">&bull;</span>
                      <span className="text">LIVE</span>
                    </span>
                    <span className="age">{liveDuration()}</span>
                  </React.Fragment>
                )}
              </div>
              {pollingEnabled() && isHLPolling() && (
                <HLPolling
                  customConfig={customConfig}
                  passcode={passcode}
                  passcodeEnabled={passcodeEnabled}
                  src={hlPollingLink()}
                />
              )}
              <div
                className="detail-description"
                dangerouslySetInnerHTML={{ __html: detailDescription() }}
              ></div>
            </div>
          )}
        </main>
      )}
    </Fragment>
  );
}
