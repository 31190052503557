import { createSlice } from '@reduxjs/toolkit';

// TODO may need rename. this also used in theoPlayer on recording latency stat
export const hlsjsStatSlice = createSlice({
  name: 'hlsjsStat',
  initialState: {
    latencyStat: {},
    playingDate: '',
  },
  reducers: {
    setLatencyStat: (state, action) => {
      state.latencyStat = action.payload;
    },
    setPlayingDate: (state, action) => {
      state.playingDate = action.payload;
    },
  },
});

export const { setLatencyStat, setPlayingDate } = hlsjsStatSlice.actions;

export default hlsjsStatSlice.reducer;
