import { useState, useEffect } from 'react';

export default function useVisibilityChange() {
  const [documentVisible, setDocumentVisible] = useState(document.visibilityState === 'visible');
  useEffect(() => {
    function handleVisibilityChange() {
      setDocumentVisible(document.visibilityState === 'visible');
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  return documentVisible;
}
