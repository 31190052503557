import { createSlice } from '@reduxjs/toolkit';

export const eventSlice = createSlice({
  name: 'event',
  initialState: {
    customization: {},
    requireLogin: false,
    requirePasscode: false,
    requirePurchase: false,
  },
  reducers: {
    setCustomization: (state, action) => {
      state.customization = action.payload;
    },
    setRequirePasscode: (state, action) => {
      state.requirePasscode = action.payload;
    },
    setRequireLogin: (state, action) => {
      state.requireLogin = action.payload;
    },
    setRequirePurchase: (state, action) => {
      state.requirePurchase = action.payload;
    },
  },
});

export const { setCustomization, setRequireLogin, setRequirePasscode, setRequirePurchase } =
  eventSlice.actions;

export default eventSlice.reducer;
