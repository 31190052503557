import { createSlice } from '@reduxjs/toolkit';

// InitialState
const initialState = {
  isLoggedIn: false,
  user: {},
  previousPath: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = {};
    },
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    setPreviousPath: (state, action) => {
      state.previousPath = action.payload;
    },
  },
});

export const { logout, login, setPreviousPath } = authSlice.actions;

export default authSlice.reducer;
