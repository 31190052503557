/**
 * Send event name in custom variable `gtm_ga4_event` to GTM
 * then fire trigger in GTM to send as `page_title` to GA4
 * finally get `unifiedScreenName` with `activeUsers` from GA4 realtime API
 */

import { createSlice } from '@reduxjs/toolkit';

const GTM_VIDEO_PROGRESS_INTERVAL = 240000; // 4 mins
const GTM_HEARTBEAT_INTERVAL = 240000; // 4 mins

function getURLForGA4Workaround(eventName) {
  let separator = window.location.href.indexOf('?') === -1 ? '?' : '&';
  let url = window.location.href + separator + 'gtm_ga4_event=' + eventName;
  return url;
}

export const gtmSlice = createSlice({
  name: 'gtm',
  initialState: {
    lastVideoProgressEventFiredTime: 0,
    firstEventFired: false,
    lastHeartbeatEventFiredTime: 0,
    firstHeartbeatEventFired: false,
  },
  reducers: {
    fireGTMHeartbeatIfNeeded: (state, action) => {
      let now = new Date().getTime();

      if (state.firstHeartbeatEventFired) {
        let shouldSend = now - state.lastHeartbeatEventFiredTime >= GTM_HEARTBEAT_INTERVAL;
        if (!shouldSend) {
          return;
        }
      }

      // logger.info('__send gtm ga4 heartbeat needed');

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'video_analytics',
        gtm_ga4_event: getURLForGA4Workaround('heartbeat'),
      });
      state.lastHeartbeatEventFiredTime = now;
      if (!state.firstHeartbeatEventFired) {
        state.firstHeartbeatEventFired = true;
      }
    },
    fireGTMVideoProgressIfNeeded: (state, action) => {
      let now = new Date().getTime();

      if (state.firstEventFired) {
        let shouldSend = now - state.lastVideoProgressEventFiredTime >= GTM_VIDEO_PROGRESS_INTERVAL;
        if (!shouldSend) {
          return;
        }
      }

      let eventName = 'video_progress';
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: eventName,
        gtm_ga4_event: getURLForGA4Workaround(eventName),
      });
      state.lastVideoProgressEventFiredTime = now;
      if (!state.firstEventFired) {
        state.firstEventFired = true;
      }
    },
    resetGTMHeartbeat: (state, action) => {
      state.firstHeartbeatEventFired = false;
      state.lastHeartbeatEventFiredTime = 0;
    },
    resetGTM: (state, action) => {
      state.firstEventFired = false;
      state.lastVideoProgressEventFiredTime = 0;
    },
  },
});

export const {
  fireGTMHeartbeatIfNeeded,
  fireGTMVideoProgressIfNeeded,
  resetGTMHeartbeat,
  resetGTM,
} = gtmSlice.actions;

export default gtmSlice.reducer;
