import dayjs from 'dayjs';
import Hls from 'hls.js';
import { appendEventLog, updateMetadata, updateLastViewResolution } from 'redux/playbackLogsSlice';
import store from 'store';

// Common Handler

const playbackLogVersion = '1.0.1';

const handlePlayerReady = (initStartTime, playerName, playerVersion) => {
  let startupTimeMs = dayjs(Date.now()).diff(dayjs(initStartTime), 'ms', true);
  store.dispatch(
    appendEventLog({
      ca: new Date().toISOString(), // createAt
      en: 'viewinit', // eventName
      st: startupTimeMs,
      pn: playerName,
      pv: playerVersion,
    })
  );
  store.dispatch(
    updateMetadata({
      pn: playerName,
      pv: playerVersion,
      plv: playbackLogVersion,
    })
  );
};

const handlePlaying = (currentTime) => {
  store.dispatch(
    appendEventLog({
      ca: new Date().toISOString(), // createAt
      en: 'playing', // eventName
      pp: currentTime, // player position
    })
  );
};

const handlePause = (currentTime) => {
  store.dispatch(
    appendEventLog({
      ca: new Date().toISOString(), // createAt
      en: 'pause', // eventName
      pp: currentTime, // player position
    })
  );
};

const handleCompleted = (currentTime) => {
  store.dispatch(
    appendEventLog({
      ca: new Date().toISOString(), // createAt
      en: 'completed', // eventName
      pp: currentTime, // player position
    })
  );
};

const handlerBufferStart = (currentTime) => {
  store.dispatch(
    appendEventLog({
      ca: new Date().toISOString(), // createAt
      en: 'buffering', // eventName
      pp: currentTime, // player position
    })
  );
};

var previousTime = 0;
var seekStart = null;
var lastTimeUpdateTs = Date.now();

const handleTimeupdate = (currentTime) => {
  let diffInSeconds = Math.abs(dayjs(Date.now()).diff(lastTimeUpdateTs, 'second'));
  if (diffInSeconds > 1) {
    previousTime = currentTime;
    lastTimeUpdateTs = Date.now();
  }
};

const handleSeeking = () => {
  if (seekStart === null) {
    seekStart = previousTime;
  }
};

const handleSeeked = (seekFrom, seekTo) => {
  store.dispatch(
    appendEventLog({
      ca: new Date().toISOString(), // createdAt
      en: 'seeked', // eventName
      skf: seekFrom,
      skt: seekTo,
    })
  );
  seekStart = null;
};

var lastResolutionChangeFiredAt = null;
const resolutionChangeThrottleDuration = 1000; // limit add rate to 1s
const handleResolutionChanged = (
  currentTime,
  videoSourceWidth,
  videoSourceHeight,
  videoSourceBitrate
) => {
  if (
    lastResolutionChangeFiredAt &&
    dayjs().diff(lastResolutionChangeFiredAt, 'millisecond') < resolutionChangeThrottleDuration
  ) {
    return;
  }
  store.dispatch(
    appendEventLog({
      ca: new Date().toISOString(), // createdAt
      en: 'resolutionchange', // eventName
      vsw: videoSourceWidth,
      vsh: videoSourceHeight,
      vsb: videoSourceBitrate,
      pp: currentTime, // player position
    })
  );
  store.dispatch(updateLastViewResolution(videoSourceHeight));
  lastResolutionChangeFiredAt = dayjs();
};

var lastErrorFiredAt = null;
const errorThrottleDuration = 1000; // limit add rate to 1s
const handleError = (currentTime, playerErrorCode, playerErrorMessage) => {
  if (lastErrorFiredAt && dayjs().diff(lastErrorFiredAt, 'millisecond') < errorThrottleDuration) {
    return;
  }
  store.dispatch(
    appendEventLog({
      ca: new Date().toISOString(), // createdAt
      en: 'error', // eventName
      pec: playerErrorCode,
      pem: playerErrorMessage,
      pp: currentTime, // player position
    })
  );
  store.dispatch(
    updateMetadata({
      // pe -> player error
      pe: {
        c: playerErrorCode,
        m: playerErrorMessage,
      },
    })
  );
  lastErrorFiredAt = dayjs();
};

/**
 * VideoJs Event Monitoring
 * @param {*} player
 * @param {*} initStartTime
 */
export const monitorVjsEvents = (player, playerVersion, initStartTime) => {
  player.on('ready', () => {
    handlePlayerReady(initStartTime, 'videojs', playerVersion);
  });

  player.on('playing', () => {
    handlePlaying(player.currentTime());
  });

  player.on('pause', () => {
    handlePause(player.currentTime());
  });

  player.on('ended', () => {
    handleCompleted(player.currentTime());
  });

  player.on('waiting', () => {
    handlerBufferStart(player.currentTime());
  });

  player.on('timeupdate', () => {
    handleTimeupdate(player.currentTime());
  });

  player.on('seeking', () => {
    handleSeeking();
  });

  player.on('seeked', () => {
    handleSeeked(seekStart, player.currentTime());
  });

  var qualityLevels = player.qualityLevels();
  qualityLevels.on('change', function () {
    const newLevel = qualityLevels[qualityLevels.selectedIndex];
    handleResolutionChanged(
      player.currentTime(),
      newLevel.width,
      newLevel.height,
      newLevel.bitrate
    );
  });

  // special handle for browser like UCBrowser.
  // and player will fire either 'change' or 'loadedmetadata'
  player.on('loadedmetadata', function (e) {
    handleResolutionChanged(player.currentTime(), player.videoWidth(), player.videoHeight(), null);
  });

  player.on('error', () => {
    handleError(player.currentTime(), player.error().code, player.error().message);
  });
};

/**
 * JwPlayer Event Monitoring
 * @param {*} player
 * @param {*} initStartTime
 */
export const monitorJwEvents = (player, playerVersion, initStartTime) => {
  player.on('ready', () => {
    handlePlayerReady(initStartTime, 'jwplayer', playerVersion);
  });

  player.on('play', () => {
    handlePlaying(player.getPosition());
  });

  player.on('pause', () => {
    handlePause(player.getPosition());
  });

  player.on('complete', () => {
    handleCompleted(player.getPosition());
  });

  player.on('buffer', () => {
    handlerBufferStart(player.getPosition());
  });

  player.on('seek', ({ position, offset }) => {
    const seekFrom = position;
    const seekTo = offset;
    handleSeeked(seekFrom, seekTo);
  });

  player.on('visualQuality', (label) => {
    handleResolutionChanged(
      player.getPosition(),
      label.level.width,
      label.level.height,
      label.level.bitrate
    );
  });

  player.on('error', (error) => {
    handleError(player.getPosition(), error.code, error.message);
  });
};

/**
 * HLS.js Event Monitoring
 * @param {*} video player
 * @param {*} hls  library
 * @param {*} initStartTime view rendering time
 */
export const monitorHlsjsEvents = (video, hls, playerVersion, initStartTime) => {
  var isFirst = true;
  hls.on(Hls.Events.MEDIA_ATTACHED, () => {
    if (isFirst) {
      handlePlayerReady(initStartTime, 'hlsjs', playerVersion);
      isFirst = false;
    }
  });

  video.addEventListener('playing', () => {
    handlePlaying(video.currentTime);
  });

  video.addEventListener('pause', () => {
    handlePause(video.currentTime);
  });

  video.addEventListener('ended', () => {
    handleCompleted(video.currentTime);
  });

  video.addEventListener('waiting', () => {
    handlerBufferStart(video.currentTime);
  });

  video.addEventListener('timeupdate', () => {
    handleTimeupdate(video.currentTime);
  });

  video.addEventListener('seeking', () => {
    handleSeeking();
  });

  video.addEventListener('seeked', () => {
    handleSeeked(seekStart, video.currentTime);
  });

  hls.on(Hls.Events.LEVEL_SWITCHED, () => {
    const levels = hls.levels;
    const currentLevel = hls.currentLevel;
    handleResolutionChanged(
      video.currentTime,
      levels[currentLevel].width,
      levels[currentLevel].height,
      levels[currentLevel].bitrate
    );
  });

  hls.on(Hls.Events.ERROR, (event, data) => {
    if (data.fatal) {
      handleError(video.currentTime, data.details, data.details);
    }
  });
};

/**
 * Theoplayer Event Monitoring
 * @param {*} player
 * @param {*} initStartTime
 */
export const monitorTheoEvents = (player, playerVersion, initStartTime) => {
  var viewinited = false;
  player.addEventListener('loadeddata', () => {
    if (!viewinited) {
      handlePlayerReady(initStartTime, 'theo', playerVersion);
      viewinited = true;
    }
  });

  player.addEventListener('playing', () => {
    handlePlaying(player.currentTime);
  });

  player.addEventListener('pause', () => {
    handlePause(player.currentTime);
  });

  player.addEventListener('ended', () => {
    handleCompleted(player.currentTime);
  });

  player.addEventListener('waiting', () => {
    handlerBufferStart(player.currentTime);
  });

  player.addEventListener('timeupdate', () => {
    handleTimeupdate(player.currentTime);
  });

  player.addEventListener('seeking', () => {
    handleSeeking();
  });

  player.addEventListener('seeked', () => {
    handleSeeked(seekStart, player.currentTime);
  });

  player.addEventListener('resize', () => {
    var bitrate = 0;
    try {
      bitrate = player.videoTracks[0].activeQuality.bandwidth;
      // known issue: first time init could fail to get the bandwidth
    } catch (e) {
      // ignore
    }
    handleResolutionChanged(player.currentTime, player.videoWidth, player.videoHeight, bitrate);
  });

  player.addEventListener('error', (errorEvent) => {
    try {
      handleError(player.currentTime, errorEvent.errorObject.code, errorEvent.errorObject.message);
    } catch (e) {
      handleError(player.currentTime, 0, 'unknown_error');
    }
  });
};
