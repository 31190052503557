/* global ga */
import { createSlice } from '@reduxjs/toolkit';

const GA_UA_VIDEO_PROGRESS_INTERVAL = 240000; // 4 mins

export const gaUaSlice = createSlice({
  name: 'gaUa',
  initialState: {
    lastVideoProgressEventFiredTime: 0,
    isVod: false,
    initialized: false,
    firstEventFired: false,
  },
  reducers: {
    initGAUA: (state, action) => {
      window.ga =
        window.ga ||
        function () {
          (ga.q = ga.q || []).push(arguments);
        };
      ga.l += new Date();
      ga('create', `${process.env.REACT_APP_GA_UA_TRACKING_ID}`, 'auto');
      state.initialized = true;
    },
    configGAUA: (state, action) => {
      if (state.initialized) {
        ga('set', 'page', `/event/${action.payload.eventId}`);
        state.isVod = action.payload.isVod;
      }
    },
    fireGAUAVideoProgressIfNeeded: (state, action) => {
      let now = new Date().getTime();
      if (!state.initialized) {
        return;
      }
      if (state.firstEventFired) {
        let shouldSend =
          now - state.lastVideoProgressEventFiredTime >= GA_UA_VIDEO_PROGRESS_INTERVAL;
        if (!shouldSend) {
          return;
        }
      }
      ga('send', 'event', {
        eventCategory: state.isVod ? 'VOD' : 'Live',
        eventAction: 'VIDEO_WATCHING_PROGRESS',
        eventLabel: JSON.stringify('{}'),
      });
      state.lastVideoProgressEventFiredTime = now;
      if (!state.firstEventFired) {
        state.firstEventFired = true;
      }
    },
    resetGAUA: (state, action) => {
      state.firstEventFired = false;
      state.lastVideoProgressEventFiredTime = 0;
      state.isVod = false;
    },
  },
});

export const { initGAUA, configGAUA, fireGAUAVideoProgressIfNeeded, resetGAUA } = gaUaSlice.actions;

export default gaUaSlice.reducer;
