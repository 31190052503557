import { createTheme } from '@mui/material';

export const mainColor = '#3FBB9B';
export const secondColor = '#0C6251';

export const theme = createTheme({
  palette: {
    theme: {
      main: mainColor,
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});
