import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import 'styles/scss/components/localeSelector.scss';

export default function LocaleSelector(props) {
  const { availableLocales } = props;
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const changeLocaleHandler = (locale) => {
    if (locale === i18n.language) {
      return;
    }
    i18n.changeLanguage(locale);
    if (searchParams.has('locale')) {
      searchParams.set('locale', locale);
    } else {
      searchParams.append('locale', locale);
    }
    setSearchParams(searchParams);
  };

  const getLocaleClassActive = (locale) => {
    if (i18n.language === locale) {
      return 'active';
    }
    return '';
  };

  return (
    <div className="locale-selector">
      {availableLocales.includes('en') && (
        <button
          className={`locale ${getLocaleClassActive('en')}`}
          onClick={(e) => changeLocaleHandler('en', e)}
        >
          EN
        </button>
      )}
      {availableLocales.includes('zh-Hant') && (
        <button
          className={`locale ${getLocaleClassActive('zh-Hant')}`}
          onClick={(e) => changeLocaleHandler('zh-Hant', e)}
        >
          繁
        </button>
      )}
      {availableLocales.includes('zh-Hans') && (
        <button
          className={`locale ${getLocaleClassActive('zh-Hans')}`}
          onClick={(e) => changeLocaleHandler('zh-Hans', e)}
        >
          简
        </button>
      )}
    </div>
  );
}
