import { Auth } from 'aws-amplify';
import store from '../store';
import { logout } from '../redux/authSlice';
import logger from 'utils/loggerUtils';

export function getMultiLocaleContent(content, locale) {
  if (typeof content === 'string') {
    return content;
  }
  return content[locale] || '';
}

export const logoutHandler = async () => {
  try {
    await Auth.signOut();
    store.dispatch(logout());
  } catch (err) {
    logger.log(err);
  }
};

export const replaceAssetDomain = (url) => {
  return url.replace(/^\/assets\//, `${process.env.REACT_APP_ASSET_BASE_URL}/assets/`);
};

export const postMessageToParent = (data, origin) => {
  const parentWindow = window.parent;
  if (parentWindow) {
    parentWindow.postMessage(data, origin);
  }
};
