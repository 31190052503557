import React, { useEffect, useRef } from 'react';
import 'styles/scss/components/hlsjsPlayer.scss';
import logger from 'utils/loggerUtils';
import { useDispatch } from 'react-redux';
import { setLatencyStat, setPlayingDate } from 'redux/hlsjsStatSlice';
import { useSelector } from 'react-redux';
import { monitorHlsjsEvents } from './playerEvents';
import { addLatencyLog } from 'redux/playbackLogsSlice';

export default function HlsjsPlayer(props) {
  const playerRef = useRef(null);
  const { options, playerStateCheckHandler, playerErrorHandler, playerStalledHandler } = props;
  const cmcdRef = useRef(options.cmcd); // should set before sourceRef
  const sourceRef = useRef(options.src);
  const reloadingRef = useRef(false);
  const fatalErrorRetryCount = useRef(0);
  const hlsjsLib = useRef(null);
  const dispatch = useDispatch();
  const { lowlatencyMode } = useSelector((state) => state.hlsjsCtr);

  const removePlayer = () => {
    if (playerRef.current && playerRef.current !== 'native') {
      logger.log('to dispose');
      playerRef.current.destroy();
      playerRef.current = null;
    }
  };

  useEffect(() => {
    if (playerRef.current && options.src && options.src !== sourceRef.current) {
      logger.log('change src');
      let video = document.getElementById('hlsjs-player');
      if (playerRef.current !== 'native') {
        playerRef.current.detachMedia();
        // update cmcd info before attach new source to ensure cmcd info is updated
        cmcdRef.current = options.cmcd;

        playerRef.current.loadSource(options.src);
        playerRef.current.attachMedia(video);
        playerRef.current.on(hlsjsLib.current.Events.MANIFEST_PARSED, function (event, data) {
          logger.log('manifest loaded');
          video.play().catch(() => {
            video.muted = true;
            video.play();
          });
        });
      } else {
        video.src = options.src;
        video.load();
        video.play().catch(() => {
          video.muted = true;
          video.play();
        });
      }
    }
    sourceRef.current = options.src;
  }, [options.cmcd, options.src]);

  useEffect(() => {
    logger.log('hlsjs mount');
    let ignore = false;
    let monitor = null;
    let statMonitor = null;
    let playingDateMonitor = null;
    let previousProgress = 0;
    const errorHandler = () => {
      playerErrorHandler();
      logger.info('error handle');
      removePlayer();
    };
    import(/* webpackChunkName: "hlsjs" */ 'hls.js')
      .then((module) => module.default)
      .then((Hlsjs) => {
        hlsjsLib.current = Hlsjs;
        if (!ignore && !playerRef.current) {
          let video = document.getElementById('hlsjs-player');

          if (Hlsjs.isSupported()) {
            // using custom controller for update the newest cmcd contentId
            class MyCMCDController extends Hlsjs.DefaultConfig.cmcdController {
              // override createData
              createData() {
                // logger.log('__cmcd cid', cmcdRef.current.contentId);
                return {
                  ...super.createData(),
                  cid: cmcdRef.current.contentId,
                };
              }
            }

            let config = {
              cmcd: cmcdRef.current,
              cmcdController: MyCMCDController,
            };
            if (options.isDrm) {
              config = {
                ...config,
                emeEnabled: true,
                widevineLicenseUrl: options.drmConfig.widevineUrl,
                licenseXhrSetup: (xhr) => {
                  xhr.setRequestHeader(
                    options.drmConfig.drmTokenHeaderName,
                    options.drmConfig.widevineToken
                  );
                  // xhr.setRequestHeader('Authorization', `Bearer ${process.env.REACT_APP_HL_TOKEN}`)
                },
                drmSystemOptions: {
                  audioRobustness: 'SW_SECURE_CRYPTO',
                  videoRobustness: 'SW_SECURE_CRYPTO',
                },
              };
            }
            const hlsjsInstance = (playerRef.current = new Hlsjs(config));

            // fine tuning
            // liveMaxLatencyDurationCount limits the max latency to 4 frament durations
            if (lowlatencyMode) {
              hlsjsInstance.config.liveMaxLatencyDuration = 8;
            } else {
              hlsjsInstance.config.liveMaxLatencyDurationCount = 4;
            }
            // maxLiveSyncPlaybackRate enables player to catch up with target latency (not stable)
            // hlsjsInstance.config.maxLiveSyncPlaybackRate = 1.1
            hlsjsInstance.lowLatencyMode = lowlatencyMode;
            logger.log('low latency mode:', lowlatencyMode);

            monitorHlsjsEvents(video, hlsjsInstance, Hlsjs.version, Date.now());

            // bind them together
            hlsjsInstance.attachMedia(video);
            // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
            hlsjsInstance.on(Hlsjs.Events.MEDIA_ATTACHED, function () {
              logger.log('video and hls.js are now bound together !');
              hlsjsInstance.loadSource(sourceRef.current);
              hlsjsInstance.on(Hlsjs.Events.MANIFEST_PARSED, function (event, data) {
                logger.log('manifest loaded');
                video.play().catch(() => {
                  video.muted = true;
                  video.play();
                });
              });
            });

            video.addEventListener('playing', () => {
              // logger.log('player playing');
              fatalErrorRetryCount.current = 0;
            });

            hlsjsInstance.on(Hlsjs.Events.ERROR, function (event, data) {
              if (data.fatal) {
                switch (data.type) {
                  case Hlsjs.ErrorTypes.NETWORK_ERROR:
                    // limit retry count to 5
                    if (fatalErrorRetryCount.current <= 5) {
                      // try to recover network error
                      // prevent retry in high frequency
                      if (!reloadingRef.current) {
                        fatalErrorRetryCount.current += 1;
                        reloadingRef.current = true;
                        logger.log('fatal network error encountered, try to recover');
                        hlsjsInstance.startLoad();
                        setTimeout(() => {
                          reloadingRef.current = false;
                          hlsjsInstance.startLoad();
                        }, 2000);
                      }
                    } else {
                      logger.log('fatal network error encountered');
                      errorHandler();
                    }
                    break;
                  case Hlsjs.ErrorTypes.MEDIA_ERROR:
                    logger.log('fatal media error encountered');
                    errorHandler();
                    break;
                  default:
                    // cannot recover
                    errorHandler();
                    break;
                }
              }
            });

            statMonitor = setInterval(() => {
              const latencyOffset = 2500;
              const latency =
                latencyOffset + parseInt(1000 * hlsjsInstance.latencyController._latency);
              dispatch(
                setLatencyStat({
                  forwardBufferLength: parseInt(
                    1000 * hlsjsInstance.latencyController.forwardBufferLength
                  ),
                  latency,
                  playbackRate: video.playbackRate,
                  curBitrate: parseInt(
                    hlsjsInstance.levels[hlsjsInstance.currentLevel].bitrate / 1000
                  ),
                })
              );

              dispatch(addLatencyLog({ latency, time: new Date().toISOString() }));
            }, 1000);

            playingDateMonitor = setInterval(() => {
              if (hlsjsInstance.playingDate) {
                dispatch(setPlayingDate(hlsjsInstance.playingDate.toISOString()));
              }
            }, 100);

            monitor = setInterval(() => {
              let currentProgress = 0;
              if (playerRef.current) {
                currentProgress = video.currentTime;
                if (previousProgress !== currentProgress) {
                  if (currentProgress !== 0 && previousProgress === 0) {
                    // this.$emit('instanceStarted')
                  }
                  playerStateCheckHandler({
                    state: 'instancePlaying',
                    previous: previousProgress,
                    current: currentProgress,
                  });
                } else {
                  if (!video.paused) {
                    playerStalledHandler({ previous: previousProgress, current: currentProgress });
                  } else {
                    playerStateCheckHandler({
                      state: 'instancePaused',
                      previous: previousProgress,
                      current: currentProgress,
                    });
                  }
                }
                previousProgress = currentProgress;
              } else {
                playerStalledHandler({ previous: previousProgress, current: currentProgress });
              }
            }, options.checkingInterval);
          } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            playerRef.current = 'native';
            video.src = sourceRef.current;
            video.play().catch(() => {
              video.muted = true;
              video.play();
            });

            video.addEventListener('error', (e) => {
              console.error(`Error: ${e}`);
              errorHandler();
            });

            monitor = setInterval(() => {
              let currentProgress = 0;
              currentProgress = video.currentTime;
              if (previousProgress !== currentProgress) {
                if (currentProgress !== 0 && previousProgress === 0) {
                  // this.$emit('instanceStarted')
                }
                playerStateCheckHandler({
                  state: 'instancePlaying',
                  previous: previousProgress,
                  current: currentProgress,
                });
              } else {
                if (!video.paused) {
                  playerStalledHandler({ previous: previousProgress, current: currentProgress });
                } else {
                  playerStateCheckHandler({
                    state: 'instancePaused',
                    previous: previousProgress,
                    current: currentProgress,
                  });
                }
              }
              previousProgress = currentProgress;
            }, options.checkingInterval);
          }
        }
      });

    return () => {
      logger.log('hlsjs unmount');
      ignore = true;
      if (monitor !== null) {
        clearInterval(monitor);
      }
      if (statMonitor !== null) {
        clearInterval(statMonitor);
      }
      if (playingDateMonitor !== null) {
        clearInterval(playingDateMonitor);
      }
      removePlayer();
    };
  }, [
    options.checkingInterval,
    options.isDrm,
    options.drmConfig.drmTokenHeaderName,
    options.drmConfig.widevineUrl,
    options.drmConfig.widevineToken,
    playerErrorHandler,
    playerStalledHandler,
    playerStateCheckHandler,
    dispatch,
    lowlatencyMode,
  ]);

  return (
    <div className={`hlsjs-container${options.isEmbed ? ' embed' : ''}`}>
      <video
        id="hlsjs-player"
        className="hlsjs-player"
        controls
        playsInline
        webkit-playsinline=""
      />
    </div>
  );
}
