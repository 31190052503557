import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './styles/scss/index.scss';
import App from './app';
import store from './store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { Amplify } from 'aws-amplify';

if (process.env.REACT_APP_BUILD_NUMBER && process.env.REACT_APP_BUILD_NUMBER !== '') {
  console.log(`build-${process.env.REACT_APP_BUILD_NUMBER}`);
}

Amplify.configure({
  Auth: {
    region: `${process.env.REACT_APP_AWS_COGNITO_REGION}`,
    userPoolId: `${process.env.REACT_APP_AWS_COGNITO_USERPOOLID}`,
    userPoolWebClientId: `${process.env.REACT_APP_AWS_COGNITO_USERPOOLWEBCLIENTID}`,
    endpoint: `https://auth.oneclickgolive.com`,
  },
  oauth: {
    domain: `${process.env.REACT_APP_AWS_COGNITO_DOMAIN}`,
    scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
    responseType: 'code',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback="loading">
          <App />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
