import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import logger from 'utils/loggerUtils';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    localStorageAvailable: null,
    browserSessionIdentifier: uuidv4(),
    globalLoading: false,
    firstEventLoaded: false,
  },
  reducers: {
    checkLocalStorageAvailability: (state) => {
      try {
        localStorage.setItem('__storageTest__', 'test');
        localStorage.removeItem('__storageTest__');
        state.localStorageAvailable = true;
      } catch (e) {
        state.localStorageAvailable = false;
      }
    },
    restoreBrowserSessionIdentifier: (state) => {
      if (state.localStorageAvailable) {
        const browserSessionIdentifier = localStorage.getItem('browserSessionIdentifier');
        if (browserSessionIdentifier) {
          state.browserSessionIdentifier = browserSessionIdentifier;
          logger.log(
            '_appState: restore [browserSessionIdentifier: %s] FROM localStorage',
            state.browserSessionIdentifier
          );
        } else {
          localStorage.setItem('browserSessionIdentifier', state.browserSessionIdentifier);
          logger.log(
            '_appState: save [browserSessionIdentifier: %s] TO localStorage',
            state.browserSessionIdentifier
          );
        }
      } else {
        logger.error('_appState: fail to restore FROM localStorage - localStorage NOT available');
      }
    },
    setGlobalLoading: (state, action) => {
      state.globalLoading = action.payload;
      if (!action.payload) {
        state.firstEventLoaded = true;
      }
    },
  },
});

export const { checkLocalStorageAvailability, restoreBrowserSessionIdentifier, setGlobalLoading } =
  appSlice.actions;

export default appSlice.reducer;
