const NO_OP = (message, ...options) => {};

class Logger {
  log() {}
  info() {}
  error() {}

  constructor(level) {
    this.level = level;

    this.error = console.error.bind(console);
    if (level === 'ERROR') {
      this.info = NO_OP;
      this.debug = NO_OP;

      return;
    }

    this.info = console.info.bind(console);
    if (level === 'INFO') {
      this.info = NO_OP;

      return;
    }

    this.log = console.log.bind(console);
  }
}

const logger = new Logger(process.env.REACT_APP_LOG_LEVEL);

export default logger;
