import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

function ParrotChatroom(props) {
  const { id, src, className } = props;
  const { i18n } = useTranslation();
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const iframeRef = useRef(null);

  const postMessageHandler = useCallback(
    (e) => {
      let iframeDomain = `${process.env.REACT_APP_PARROT_CLIENT_BASE_URL}`;
      if (e.origin === iframeDomain) {
        if (e.data.action === 'INITIALIZATION:DONE') {
          setIframeLoaded(true);
          let locale = 'en';
          if (i18n.language) {
            locale = i18n.language;
          }
          iframeRef.current.contentWindow.postMessage(
            {
              action: 'LOCALE:CHANGE',
              content: {
                value: locale,
              },
            },
            iframeDomain
          );
        }
      }
    },
    [i18n.language]
  );

  useEffect(() => {
    window.addEventListener('message', postMessageHandler, false);

    return () => {
      window.removeEventListener('message', postMessageHandler);
    };
  }, [postMessageHandler]);

  useEffect(() => {
    if (iframeLoaded) {
      let iframeDomain = `${process.env.REACT_APP_PARROT_CLIENT_BASE_URL}`;
      let locale = 'en';
      if (i18n.language) {
        locale = i18n.language;
      }
      iframeRef.current.contentWindow.postMessage(
        {
          action: 'LOCALE:CHANGE',
          content: {
            value: locale,
          },
        },
        iframeDomain
      );
    }
  }, [i18n.language, iframeLoaded]);

  return (
    <iframe
      id={id}
      src={src}
      className={className}
      ref={iframeRef}
      frameBorder="0"
      title="chatroom"
    ></iframe>
  );
}

export default ParrotChatroom;
