import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Player from 'components/player';
import { useEventManipulate } from './useEventManipulate';
import { useParams } from 'react-router-dom';
import PasscodePage from 'components/passcodePage';
import NotFound from 'components/notFound';

import 'styles/scss/routes/embed.scss';

export default function Embed(props) {
  const params = useParams();
  const { t } = useTranslation();
  const {
    backgroundImage,
    event,
    stream,
    streamId,
    isReady,
    isDrm,
    isVod,
    audioReference,
    customStyle,
    playerProblemHandler,
    displayPasscodePage,
    title,
    passcodeValidating,
    validatePasscodeSuccessHandler,
    audioChangeHandler,
    getDisplayMessage,
    customConfig,
    notFound,
  } = useEventManipulate(props);
  const [userActive, setUserActive] = useState(true);

  const userActiveHandler = useCallback(() => {
    setUserActive(true);
  }, []);

  const userInactiveHandler = useCallback(() => {
    setUserActive(false);
  }, []);

  const wrappedValidatePasscodeSuccessHandler = useCallback(
    (validateResponseData, saveCookies) => {
      validatePasscodeSuccessHandler(validateResponseData, event.isStatic, saveCookies);
    },
    [event.isStatic, validatePasscodeSuccessHandler]
  );

  return (
    <Fragment>
      {notFound ? (
        <NotFound />
      ) : (
        <main className="embed-page" style={customStyle}>
          {displayPasscodePage() ? (
            <PasscodePage
              eventId={params.eventId}
              title={title()}
              passcodeValidating={passcodeValidating}
              validatePasscodeSuccessHandler={wrappedValidatePasscodeSuccessHandler}
            ></PasscodePage>
          ) : (
            <div className="event-container">
              {streamId && audioReference() && audioReference().length > 1 && userActive && (
                <div className="active-header">
                  <div className="audio-selector-wrapper">
                    <select
                      className="audio-selector"
                      value={streamId}
                      onChange={audioChangeHandler}
                    >
                      {audioReference().map((reference) => (
                        <option value={reference.eventId} key={reference.eventId}>
                          {getDisplayMessage(
                            t(`event.si.${reference.language}`),
                            reference.customDisplayName
                          )}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <Player
                backgroundImage={backgroundImage()}
                hlsPlaybackUrl={stream.hlsPlaybackUrl}
                dashPlaybackUrl={stream.dashPlaybackUrl}
                isReady={isReady}
                isDrm={isDrm()}
                isVod={isVod()}
                fakeLiveLayout={false}
                playerProblemHandler={playerProblemHandler}
                ignoreChrome105VjsFix={event.ignoreChrome105VjsFix === true}
                isEmbed={true}
                manualTriggerControl={customConfig.manualTriggerControl}
                userActiveHandler={userActiveHandler}
                userInactiveHandler={userInactiveHandler}
              ></Player>
            </div>
          )}
        </main>
      )}
    </Fragment>
  );
}
