import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import appReducer from 'redux/appSlice';
import authReducer from 'redux/authSlice';
import eventReducer from 'redux/eventSlice';
import gtmReducer from 'redux/gtmSlice';
import gaUaReducer from 'redux/gaUaSlice';
import playbackLogsReducer from 'redux/playbackLogsSlice';
import hlsjsStatReducer from 'redux/hlsjsStatSlice';
import hlsjsCtrReducer from 'redux/hlsjsCtrSlice';

export default configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    event: eventReducer,
    playbackLogs: playbackLogsReducer,
    gtmReducer: gtmReducer,
    gaUa: gaUaReducer,
    hlsjsStat: hlsjsStatReducer,
    hlsjsCtr: hlsjsCtrReducer,
  },
  middleware: [thunk],
});
