import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  setCustomization,
  setRequireLogin as reduxSetRequireLogin,
  setRequirePasscode,
} from 'redux/eventSlice';
import { setGlobalLoading } from 'redux/appSlice';
import { configGAUA } from 'redux/gaUaSlice';
import { updatePlaybackLogsCoreInfo, updatePlaybackLogsUserInfo } from 'redux/playbackLogsSlice';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { DialogContext } from 'components/dialogContext';
import * as API from 'api/baseAPI';
import * as cookiesUtils from 'utils/cookiesUtils';
import { getUserAgent } from 'utils/uaUtils';
import { getMultiLocaleContent } from 'utils/helper';
import logger from 'utils/loggerUtils';

import banner from 'images/banner.jpg';
import startFlip from 'images/start.jpg';
import endFlip from 'images/end.jpg';

export const useEventManipulate = (props) => {
  const { eventValidatedHandler, exitValidatedSession } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [initialized, setInitialized] = useState(false);
  const [stream, setStream] = useState({});
  const live2VodStatusRef = useRef(null);
  const [passcodeEnabled, setPasscodeEnabled] = useState(false);
  const [passcodeValidateInterval, setPasscodeValidateInterval] = useState(null);
  const [passcode, setPasscode] = useState(null);
  const [passcodeToken, setPasscodeToken] = useState(null);
  // const [passcodeMetadata, setPasscodeMetadata] = useState(null)
  const [passcodeValidating, setPasscodeValidating] = useState(false);
  const [consecutiveFailedCount, setConsecutiveFailedCount] = useState(0);
  const [now, setNow] = useState();
  const [streamId, setStreamId] = useState(null);
  const [event, setEvent] = useState({});
  const [isReady, setIsReady] = useState(false);
  const [selectedTab, setSelectedTab] = useState('info');
  const customization = useSelector((state) => state.event.customization);
  const [customStyle, setCustomStyle] = useState({});
  const [customConfig, setCustomConfig] = useState({
    /* defaultTab: null, */
    manualTriggerControl: false,
    pollingThemeColor: '#3FBB9B',
    questionBoxPlaceholder: null,
    enableCasting: false,
    selectChannelAtBottom: false,
  });
  const [playerHasProblem, setPlayerHasProblem] = useState(false);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const [question, setQuestion] = useState('');
  const [questionSubmitting, setQuestionSubmitting] = useState(false);
  const [requireLogin, setRequireLogin] = useState(false);
  const [loginValidated, setLoginValidated] = useState(false);
  const [validLoginToken, setValidLoginToken] = useState(null);
  const [sessionKicked, setSessionKicked] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const updateStream = useCallback((newStream) => {
    let _hlsPlaybackUrl = null;
    let _dashPlaybackUrl = null;
    if (newStream.stream && newStream.stream.playback) {
      if (newStream.stream.playback.hlsUrl) {
        _hlsPlaybackUrl = newStream.stream.playback.hlsUrl;
      }
      if (newStream.stream.playback.dashUrl) {
        _dashPlaybackUrl = newStream.stream.playback.dashUrl;
      }
    }
    setStream((s) => ({
      ...s,
      status: newStream.status,
      live2VodStatus: newStream.live2VodStatus,
      actualStartDatetime: newStream.actualStartDatetime,
      actualEndDatetime: newStream.actualEndDatetime,
      hlsPlaybackUrl: _hlsPlaybackUrl,
      dashPlaybackUrl: _dashPlaybackUrl,
    }));
    if (live2VodStatusRef.current === null && newStream.live2VodStatus) {
      live2VodStatusRef.current = newStream.live2VodStatus;
    }
  }, []);

  const updateEvent = useCallback(
    (newEvent) => {
      setEvent(newEvent);
      if (newEvent.customization) {
        dispatch(setCustomization(newEvent.customization));
      }
    },
    [dispatch]
  );

  const tabChangeHandler = (e, newValue) => {
    if (newValue) {
      setSelectedTab(newValue);
    }
  };

  const title = () => {
    if (event && event.title) {
      return getMultiLocaleContent(event.title, i18n.language);
    }
    return '';
  };

  const description = () => {
    if (event && event.description) {
      return getMultiLocaleContent(event.description, i18n.language);
    }
    return '';
  };

  const detailDescription = () => {
    if (event && event.detailDescription) {
      return getMultiLocaleContent(event.detailDescription, i18n.language);
    }
    return '';
  };

  const questionEnabled = () => {
    if (event && event.question && event.question.enable) {
      return event.question.enable === true;
    }
    return false;
  };

  const isHLQuestion = () => {
    if (event && event.question && event.question.isHL) {
      return event.question.isHL === true;
    }
    return false;
  };

  const slidoQuestionLink = () => {
    if (event && event.question && event.question.slidoLink) {
      let locale = 'en_US';
      const localeMapping = {
        en: 'en_US',
        'zh-Hans': 'zh_hans',
        'zh-Hant': 'zh_hant',
      };
      let slidoQueryParam = '';
      if (i18n.language) {
        locale = localeMapping[i18n.language];
      }
      if (passcodeEnabled && passcode) {
        slidoQueryParam = `${slidoQueryParam}&user_name=${passcode}`;
      }
      /* if (this.hasPasscode && this.passcodeMetadata && this.passcodeMetadata.username) {
        slidoQueryParam = `${slidoQueryParam}&user_name=${this.passcodeMetadata.username}`
      } else if (this.validatePasscodeResult && this.validatePasscodeResult.passcode && this.hasPasscode) {
        slidoQueryParam = `${slidoQueryParam}&user_name=${this.validatePasscodeResult.passcode}`
      }
      if (this.hasPasscode && this.passcodeMetadata && this.passcodeMetadata.emailaddress) {
        slidoQueryParam = `${slidoQueryParam}&user_email=${this.passcodeMetadata.emailaddress}`
      } */

      return `${event.question.slidoLink}/questions?lang=${locale}${slidoQueryParam}`;
    }
    return null;
  };

  const questionSubmitHandler = (e) => {
    e.preventDefault();
    if (questionSubmitting) {
      return;
    }
    if (question === '') {
      return;
    }
    if (question.length > 200) {
      openDialog(t('event.question.exceeds-char-limit-message'), () => {
        closeDialog();
      });
      return;
    }
    setQuestionSubmitting(true);

    let data = {
      text: question,
      eventId: params.eventId,
    };
    if (passcodeEnabled && passcode) {
      data['questioner'] = `${passcode}`;
    }

    let submitQuestion = isHLQuestion() ? API.submitHLQuestion : API.submitQuestion;

    submitQuestion(data)
      .then(() => {
        openDialog(t('event.question.succeed-message'), () => {
          closeDialog();
        });
        setQuestionSubmitting(false);
        setQuestion('');
      })
      .catch(() => {
        openDialog(t('event.question.failed-message'), () => {
          closeDialog();
        });
        setQuestionSubmitting(false);
      });
  };

  const questionChangeHandler = (e) => {
    setQuestion(e.target.value);
  };

  const pollingEnabled = () => {
    if (event && event.polling && event.polling.enable) {
      return event.polling.enable === true;
    }
    return false;
  };

  const isHLPolling = () => {
    if (event && event.polling && event.polling.isHL) {
      return event.polling.isHL === true;
    }
    return false;
  };

  const parrotPollingLink = () => {
    if (event && event.polling) {
      if (passcodeEnabled && passcode) {
        // const voter = this.passcodeMetadata.email
        const voter = passcode;
        return `${process.env.REACT_APP_PARROT_CLIENT_BASE_URL}/embed/${params.eventId}/polling?user=${voter}`;
      } else {
        return `${process.env.REACT_APP_PARROT_CLIENT_BASE_URL}/embed/${params.eventId}/polling`;
      }
    }
  };

  const slidoPollingLink = () => {
    if (event && event.polling && event.polling.slidoLink) {
      let locale = 'en_US';
      const localeMapping = {
        en: 'en_US',
        'zh-Hans': 'zh_hans',
        'zh-Hant': 'zh_hant',
      };
      let slidoQueryParam = '';
      if (i18n.language) {
        locale = localeMapping[i18n.language];
      }
      if (passcodeEnabled && passcode) {
        slidoQueryParam = `${slidoQueryParam}&user_name=${passcode}`;
      }
      /* if (this.hasPasscode && this.passcodeMetadata && this.passcodeMetadata.username) {
        slidoQueryParam = `${slidoQueryParam}&user_name=${this.passcodeMetadata.username}`
      } else if (this.validatePasscodeResult && this.validatePasscodeResult.passcode && this.hasPasscode) {
        slidoQueryParam = `${slidoQueryParam}&user_name=${this.validatePasscodeResult.passcode}`
      }
      if (this.hasPasscode && this.passcodeMetadata && this.passcodeMetadata.emailaddress) {
        slidoQueryParam = `${slidoQueryParam}&user_email=${this.passcodeMetadata.emailaddress}`
      } */

      return `${event.polling.slidoLink}/polls?lang=${locale}${slidoQueryParam}`;
    }
    return null;
  };

  const backgroundImage = () => {
    let defaultBanner = banner;
    let defaultStart = startFlip;
    let defaultEnd = endFlip;
    let customBanner = null;
    let customEnd = null;
    let customStart = null;
    if (event.mediaAssets) {
      event.mediaAssets.forEach((mediaAsset) => {
        if (mediaAsset.imageType === 'banner') {
          customBanner = mediaAsset.url;
        }
        if (mediaAsset.imageType === 'end_flip') {
          customEnd = mediaAsset.url;
        }
        if (mediaAsset.imageType === 'start_flip') {
          customStart = mediaAsset.url;
        }
      });
    }
    if (customBanner) {
      defaultBanner = customBanner;
      defaultEnd = customBanner;
      defaultStart = customBanner;
    }
    if (customStart) {
      defaultStart = customStart;
    }
    if (customEnd) {
      defaultEnd = customEnd;
    }
    if (stream) {
      if (stream.status === 'live' || stream.status === 'live_starting') {
        return defaultStart;
      } else if (stream.status === 'end') {
        return defaultEnd;
      }
      return defaultBanner;
    }
    return defaultBanner;
  };

  const isDrm = () => {
    if (stream && stream.drmConfig && stream.drmConfig.enable === true) {
      return true;
    }
    return false;
  };

  const audioReference = () => {
    if (event && event.audioReference && Object.keys(event.audioReference).length > 0) {
      return event.audioReference;
    }
    return null;
  };

  const audioChangeHandler = (e) => {
    const streamId = e.target.value;
    API.getStream(streamId, event.isStatic, passcodeToken)
      .then((response) => {
        if (response && response.data) {
          setStreamId(streamId);
          if (response.data.live2VodStatus) {
            live2VodStatusRef.current = response.data.live2VodStatus;
          }
          updateStream(response.data);
        }
      })
      .catch((err) => {
        logger.error(err);
      });
  };

  const isLive = useCallback(() => {
    if (stream.status === 'live') {
      return true;
    }
    return false;
  }, [stream.status]);

  const isVod = useCallback(() => {
    if (
      stream.live2VodStatus &&
      stream.live2VodStatus === 'done' &&
      live2VodStatusRef.current === 'done'
    ) {
      return true;
    }
    return false;
  }, [stream.live2VodStatus]);

  const displayPasscodePage = useCallback(() => {
    if (passcodeEnabled && !stream.hlsPlaybackUrl && !stream.dashPlaybackUrl) {
      return true;
    }
    return false;
  }, [passcodeEnabled, stream.dashPlaybackUrl, stream.hlsPlaybackUrl]);

  const validatePasscodeSuccessHandler = useCallback(
    (validateResponseData, isStatic, saveCookies) => {
      setPasscodeValidating(true);
      API.getStream(params.eventId, isStatic, validateResponseData.token)
        .then((streamResponse) => {
          if (streamResponse && streamResponse.data) {
            setPasscode(validateResponseData.passcode);
            setPasscodeToken(validateResponseData.token);
            setStreamId(params.eventId);
            updateStream(streamResponse.data);
            dispatch(
              updatePlaybackLogsUserInfo({
                userIdentifier: validateResponseData.passcode,
                userSessionIdentifier: validateResponseData.token,
              })
            );
            if (saveCookies) {
              let expireDate = new Date();
              expireDate.setHours(expireDate.getHours() + 12);
              cookiesUtils.setCookie(
                `PCT-${params.eventId}`,
                validateResponseData.token,
                true,
                expireDate.toGMTString()
              );
            }
            if (validateResponseData.metadata) {
              // setPasscodeMetadata(data.metadata)
            }
          }
          setPasscodeValidating(false);
          dispatch(setGlobalLoading(false));
        })
        .catch((err) => {
          logger.error(err);
          setPasscodeValidating(false);
          dispatch(setGlobalLoading(false));
          // TODO enhance handling, will stay on passcode page without pop up
        });
      eventValidatedHandler(true);
    },
    [params.eventId, eventValidatedHandler, updateStream, dispatch]
  );

  const kickPasscodeSession = useCallback(
    (message) => {
      // allow stream monitor to ignore ongoning api call
      // to fix cannot show passcode page after session kicked due to timing issue
      setStreamId(null);
      setStream((s) => ({
        ...s,
        hlsPlaybackUrl: null,
        dashPlaybackUrl: null,
      }));
      cookiesUtils.deleteCookie(`PCT-${params.eventId}`);
      setPasscode(null);
      setPasscodeToken(null);
      eventValidatedHandler(false);
      dispatch(
        updatePlaybackLogsUserInfo({
          userIdentifier: null,
          userSessionIdentifier: null,
        })
      );
      if (message) {
        openDialog(message, () => {
          closeDialog();
        });
      }
    },
    [params.eventId, closeDialog, openDialog, eventValidatedHandler, dispatch]
  );

  const kickLoginSession = useCallback(
    (message) => {
      setLoginValidated(false);
      setValidLoginToken(null);
      setSessionKicked(true);
      dispatch(
        updatePlaybackLogsUserInfo({
          userIdentifier: null,
          userSessionIdentifier: null,
        })
      );
      if (message) {
        openDialog(message, () => {
          closeDialog();
        });
      }
    },
    [closeDialog, dispatch, openDialog]
  );

  useEffect(() => {
    if (consecutiveFailedCount >= 3 && passcodeEnabled && passcodeToken) {
      kickPasscodeSession(t('event.connection-error-message'));
      setConsecutiveFailedCount(0);
    }
  }, [consecutiveFailedCount, kickPasscodeSession, passcodeEnabled, passcodeToken, t]);

  useEffect(() => {
    let timer = null;
    let ignore = false;

    if (passcodeEnabled && passcodeValidateInterval && passcodeToken) {
      timer = setInterval(() => {
        const data = {
          token: passcodeToken,
          logAccess: false,
        };
        API.validatePasscode(params.eventId, data)
          .then(() => {
            setConsecutiveFailedCount(0);
          })
          .catch((err) => {
            if (!ignore) {
              if (err && err.response && err.response.status === 403) {
                setConsecutiveFailedCount(0);
                kickPasscodeSession(t('event.concurrent-error-message'));
              } else {
                setConsecutiveFailedCount((count) => count + 1);
              }
            }
          });
      }, passcodeValidateInterval * 1000);
    }
    return () => {
      ignore = true;
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, [
    passcodeEnabled,
    passcodeValidateInterval,
    passcodeToken,
    params.eventId,
    stream.status,
    kickPasscodeSession,
    t,
  ]);

  useEffect(() => {
    if (playerHasProblem) {
      API.getStream(streamId, event.isStatic, passcodeToken)
        .then((response) => {
          if (response && response.data) {
            updateStream(response.data);
          }
          setPlayerHasProblem(false);
        })
        .catch((err) => {
          logger.error(err);
          setPlayerHasProblem(false);
        });
    }
  }, [playerHasProblem, streamId, event.isStatic, passcodeToken, updateStream]);

  const playerProblemHandler = useCallback(() => {
    setPlayerHasProblem(true);
  }, []);

  const getDisplayMessage = (text, customText) => {
    if (customText) {
      if (i18n.language) {
        return customText[i18n.language];
      }
    }
    return text;
  };

  // init event
  useEffect(() => {
    if (!params.eventId) {
      return;
    }
    // prevent this useEffect triggered again
    if (initialized) {
      return;
    }
    setInitialized(true);
    dispatch(setGlobalLoading(true));

    logger.log('init event');
    API.getEvent(params.eventId)
      .then((response) => {
        if (response && response.data) {
          if (typeof response.data !== 'object') {
            throw new Error('Invalid Event');
          }
          updateEvent(response.data);
          let passcodeEnabled = false;
          let _requireLogin = response.data.login ? response.data.login.enable === true : false;
          if (response.data && response.data.passcode && response.data.passcode.enable) {
            passcodeEnabled = true;
            if (response.data.passcode.validateInterval) {
              setPasscodeValidateInterval(response.data.passcode.validateInterval);
            }
          } else {
            passcodeEnabled = false;
          }
          setPasscodeEnabled(passcodeEnabled);
          dispatch(setRequirePasscode(passcodeEnabled));
          if (passcodeEnabled) {
            const token = cookiesUtils.readCookie(`PCT-${params.eventId}`);
            if (token) {
              if (searchParams.has('token')) {
                // let passcode page handle
                cookiesUtils.deleteCookie(`PCT-${params.eventId}`);
              } else {
                const data = {
                  token: token,
                  logAccess: true,
                };
                API.validatePasscode(params.eventId, data)
                  .then((validateResponse) => {
                    validatePasscodeSuccessHandler(validateResponse.data, response.data.isStatic);
                  })
                  .catch((err) => {
                    logger.error(err);
                    dispatch(setGlobalLoading(false));
                    // will show passcode page as not inputted passcode yet
                  });
              }
            } else {
              dispatch(setGlobalLoading(false));
            }
          } else if (_requireLogin) {
            // handled by other useEffect
            // dispatch(setGlobalLoading(false))
            dispatch(reduxSetRequireLogin(true));
            setRequireLogin(true);
          } else {
            API.getStream(params.eventId, response.data.isStatic)
              .then((streamResponse) => {
                if (streamResponse && streamResponse.data) {
                  setStreamId(params.eventId);
                  updateStream(streamResponse.data);
                }
                dispatch(setGlobalLoading(false));
              })
              .catch((err) => {
                logger.error(err);
                dispatch(setGlobalLoading(false));
                // TODO enhance handling, will stay idle forever
              });
          }
        }
      })
      .catch((err) => {
        logger.error(err);
        setNotFound(true);
        dispatch(setGlobalLoading(false));
      });
  }, [
    initialized,
    params.eventId,
    updateStream,
    updateEvent,
    searchParams,
    dispatch,
    validatePasscodeSuccessHandler,
    eventValidatedHandler,
  ]);

  const validateLoginEmail = useCallback(() => {
    const data = {
      passcode: user.email,
      logAccess: true,
    };
    API.validatePasscode(params.eventId, data)
      .then((response) => {
        setValidLoginToken(response.data.token);
        setLoginValidated(true);
        dispatch(
          updatePlaybackLogsUserInfo({
            userIdentifier: user.id,
            userSessionIdentifier: response.data.token,
          })
        );
      })
      .catch((err) => {
        logger.error(err);
        dispatch(setGlobalLoading(false));
        // will show incorrect email message as logged in with invalid email
      });
  }, [dispatch, params.eventId, user]);

  // handle require login and user login
  useEffect(() => {
    if (requireLogin) {
      if (isLoggedIn) {
        validateLoginEmail();
      } else {
        setLoginValidated(false);
        setValidLoginToken(null);
        dispatch(setGlobalLoading(false));
      }
    }
  }, [dispatch, isLoggedIn, requireLogin, validateLoginEmail]);

  // handle require login and user valid
  useEffect(() => {
    if (requireLogin) {
      if (loginValidated) {
        API.getStream(params.eventId, event.isStatic, false)
          .then((streamResponse) => {
            if (streamResponse && streamResponse.data) {
              setStreamId(params.eventId);
              updateStream(streamResponse.data);
            }
            dispatch(setGlobalLoading(false));
          })
          .catch((err) => {
            logger.error(err);
            dispatch(setGlobalLoading(false));
            // TODO enhance handling, will stay idle forever
          });
      } else {
        setStreamId(null);
        setStream({});
      }
    }
  }, [dispatch, event.isStatic, loginValidated, params.eventId, requireLogin, updateStream]);

  // to validate single session for require login event
  useEffect(() => {
    let timer = null;
    let ignore = false;
    if (validLoginToken) {
      timer = setInterval(() => {
        const data = {
          token: validLoginToken,
          logAccess: false,
        };
        API.validatePasscode(params.eventId, data)
          .then(() => {
            setConsecutiveFailedCount(0);
          })
          .catch((err) => {
            if (!ignore) {
              if (err && err.response && err.response.status === 403) {
                setConsecutiveFailedCount(0);
                kickLoginSession(t('event.concurrent-error-message'));
              } else {
                setConsecutiveFailedCount((count) => count + 1);
              }
            }
          });
      }, 60 * 1000);
    }
    return () => {
      ignore = true;
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, [kickLoginSession, params.eventId, t, validLoginToken]);

  // cleanup when leave the page
  useEffect(() => {
    return () => {
      document.title = process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE;
      dispatch(setCustomization({}));
      dispatch(setRequirePasscode(false));
      dispatch(reduxSetRequireLogin(false));
      eventValidatedHandler(false);
    };
  }, [dispatch, eventValidatedHandler]);

  // handle "exit" button clicked
  useEffect(() => {
    if (exitValidatedSession) {
      kickPasscodeSession();
    }
  }, [params.eventId, exitValidatedSession, kickPasscodeSession]);

  // for create stream monitor
  useEffect(() => {
    let streamMonitor = null;
    let ignored = false;
    if (streamId) {
      logger.log('initial monitor');
      streamMonitor = setInterval(() => {
        setNow(Date.now());
        if (stream.status !== 'live' && stream.status !== 'end') {
          API.getStream(streamId, event.isStatic, passcodeToken)
            .then((response) => {
              if (response && response.data && !ignored) {
                updateStream(response.data);
              }
              setConsecutiveFailedCount(0);
            })
            .catch((err) => {
              logger.error(err);
              if (passcodeEnabled && passcodeToken) {
                if (err && err.response && err.response.status === 403) {
                  setConsecutiveFailedCount(0);
                  kickPasscodeSession(t('event.concurrent-error-message'));
                } else if (passcodeValidateInterval) {
                  // not kick user for non 403 fail to get stream
                  // setConsecutiveFailedCount((count) => count + 1)
                }
              }
            });
        }
      }, parseInt(process.env.REACT_APP_EVENT_POLLING_INTERVAL_SECOND) * 1000);
    }

    return () => {
      ignored = true;
      if (streamMonitor !== null) {
        logger.log('destroy monitor');
        clearInterval(streamMonitor);
      }
    };
  }, [
    params.eventId,
    streamId,
    stream.status,
    updateStream,
    event.isStatic,
    passcodeToken,
    kickPasscodeSession,
    passcodeEnabled,
    passcodeValidateInterval,
    t,
  ]);

  useEffect(() => {
    logger.log('check is ready');
    if (isLive() || isVod()) {
      setIsReady(true);
    } else {
      setIsReady(false);
    }
  }, [isLive, isVod]);

  const updatePlaybackLogsInfo = useCallback(() => {
    dispatch(
      updatePlaybackLogsCoreInfo({
        eventId: params.eventId,
        userAgent: getUserAgent(),
        isVod: isVod(),
        isLive: isLive(),
      })
    );
    dispatch(
      configGAUA({
        eventId: params.eventId,
        isVod: isVod(),
      })
    );
  }, [params.eventId, dispatch, isLive, isVod]);

  const liveDuration = () => {
    let durationInSecond = 0;
    if (stream.actualStartDatetime) {
      const _now = dayjs(now);
      const _startDatetime = dayjs(stream.actualStartDatetime);
      durationInSecond = _now.diff(_startDatetime, 'second');
    }
    if (durationInSecond < 60) {
      return t('event.just-started');
    } else if (durationInSecond < 3600) {
      return t('event.started-ago', {
        age: t('general.minute', { count: Math.round(durationInSecond / 60) }),
      });
    } else {
      return t('event.started-ago', {
        age: t('general.hour', { count: Math.round(durationInSecond / 3600) }),
      });
    }
  };

  useEffect(() => {
    updatePlaybackLogsInfo();
  }, [isReady, updatePlaybackLogsInfo]);

  useEffect(() => {
    if (customization.event) {
      if (customization.event.style) {
        let customStyle = {};
        for (const [key, value] of Object.entries(customization.event.style)) {
          customStyle[key] = value;
        }
        setCustomStyle(customStyle);
      }
      if (customization.event.config) {
        setCustomConfig((customConfig) => ({
          ...customConfig,
          ...customization.event.config,
        }));
        if (customization.event.config.defaultTab) {
          setSelectedTab(customization.event.config.defaultTab);
        }
      }
    }
  }, [customization]);

  useEffect(() => {
    if (event.title) {
      const title = getMultiLocaleContent(event.title, i18n.language);
      if (title !== '') {
        document.title = `${title} | ${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE}`;
      } else {
        document.title = process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE;
      }
    }
  }, [event.title, i18n.language]);

  return {
    backgroundImage,
    isReady,
    isDrm,
    isLive,
    isVod,
    playerProblemHandler,
    streamId,
    event,
    stream,
    title,
    description,
    detailDescription,
    questionEnabled,
    isHLQuestion,
    slidoQuestionLink,
    question,
    setQuestion,
    questionSubmitHandler,
    questionChangeHandler,
    questionSubmitting,
    pollingEnabled,
    isHLPolling,
    parrotPollingLink,
    slidoPollingLink,
    audioReference,
    customStyle,
    customConfig,
    selectedTab,
    tabChangeHandler,
    displayPasscodePage,
    passcode,
    passcodeEnabled,
    validatePasscodeSuccessHandler,
    passcodeValidating,
    audioChangeHandler,
    getDisplayMessage,
    liveDuration,
    requireLogin,
    loginValidated,
    sessionKicked,
    validateLoginEmail,
    notFound,
  };
};
